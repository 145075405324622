import React, { useState } from 'react';
import { Col, Row, Card, Form, Input, Button, message, Popconfirm } from 'antd';
import { process, UPDATE } from '../../Service/Api';
import { useAuth } from '../../Hooks';
import { useFetchUsers } from '../../Hooks/User.hook';
import logo from '../../Assets/img/avatar-294480.svg';
export const ProfileTable = () => {
  //Form Final
  const [formData, setFormData] = useState({});
  const [updater] = useFetchUsers();
  const [{ user }] = useAuth();

  const _handleDeactivate = async sId => {
    const response = await process(UPDATE, 'users', formData ? formData : {}, {
      id: sId,
    });
    if (response?.ok) {
      message.success('Actualizado correctamente');
      updater();
    } else {
      message.error('Error al Actualizar');
    }
  };
  const onFinish = () => {
    if (formData) {
      _handleDeactivate(user._id);
    }
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <h3>Perfil</h3>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Card title={` Tipo de usuario: ${user.rol}`} bordered={false}>
            Imagen:
            <img src={user.image != null ? user.image : logo} width="80px" />
          </Card>
        </Col>
        <Col span={4}></Col>
        <Col span={12}>
          <Card title="Información" bordered={false}>
            <Form onFinish={onFinish}>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Form.Item
                    label="Usuario"
                    name="username"
                    rules={[{ required: true, message: 'Campo requerido' }]}
                  >
                    <Input
                      onChange={e =>
                        setFormData({
                          ...formData,
                          username: e.target.value,
                        })
                      }
                      defaultValue={`${user.username}`}
                      type="text"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Campo requerido' }]}
                  >
                    <Input
                      onChange={e =>
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        })
                      }
                      defaultValue={`${user.email}`}
                      type="email"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Nombre"
                    name="full_name"
                    rules={[{ required: true, message: 'Campo requerido' }]}
                  >
                    <Input
                      onChange={e =>
                        setFormData({
                          ...formData,
                          full_name: e.target.value,
                        })
                      }
                      defaultValue={`${user.full_name}`}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Form.Item label="Contraseña" name="password">
                    <Input
                      onChange={e =>
                        setFormData({
                          ...formData,
                          password: e.target.value,
                        })
                      }
                      type="password"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Repetir contraseña" name="rpassword">
                    <Input
                      onChange={e =>
                        setFormData({
                          ...formData,
                          rpassword: e.target.value,
                        })
                      }
                      type="password"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Popconfirm
                    onConfirm={() => onFinish()}
                    title="Está seguro de actualiza tus datos?"
                  >
                    <Button type="primary" htmlType="submit">
                      Guardar
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
