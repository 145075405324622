import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Upload, Form, message, Modal, Select, Row } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import EXCELJS from 'exceljs';
import { SAVE, process } from '../../Service/Api';
import { useFetchAccounts, useFetchClients } from '../../Hooks';
import fileSaver from 'file-saver';

const { Dragger } = Upload;
const MultipleModal = () => {
  const [account, loading] = useFetchAccounts();
  const [clients, loading2] = useFetchClients();
  const [modal, setModal] = useState(false);
  const [new_employees, setNewEmployees] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const uploader_props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76', // ?? Asi esta en antd
    beforeUpload(file) {
      const isExcel =
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        message.error('Solo se permiten archivos de Excel');
      }
      return isExcel || Upload.LIST_IGNORE;
    },
    onChange(info) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.slice(-1);
      setFileList(newFileList);
    },
  };

  // Column validation
  const columns = [
    'fecha',
    'retiro',
    'deposito',
    'saldo',
    'tipo',
    'observaciones',
    'monto de retorno',
  ];

  const columns2 = [
    'date',
    'withdrawal',
    'deposit',
    'balance',
    'movement_type',
    'description',
    'return_amount',
  ];

  const handleSubmit = async values => {
    const workbook = new EXCELJS.Workbook();
    await workbook.xlsx.load(fileList[0].originFileObj);
    const worksheet = workbook.getWorksheet(1);
    const row1 = worksheet.getRow(1);
    const movementsType = ['tranferencia', 'efectivo', 'asimilado'];

    for (let i = 0; i < columns.length; i++) {
      if (row1.getCell(i + 1).value.toLowerCase() !== columns[i]) {
        message.error('El archivo no tiene las columnas correctas');
        return;
      }
    }

    const employees = [];
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber === 1) {
        return;
      }
      const employee = {};
      //Search within the columns for the type of movements to pass it to the number for the api
      row.eachCell((cell, colNumber) => {
        if (columns2[colNumber - 1] === 'movement_type') {
          const movementIndex = movementsType.indexOf(
            cell.value.trim().toLowerCase()
          );
          employee[columns2[colNumber - 1]] =
            movementIndex !== -1 ? movementIndex : cell.value;
        } else {
          employee[columns2[colNumber - 1]] = cell.value;
        }
      });
      employee.account_id = values.account_id;
      employee.client_id = values.client_id;
      employee.status = 0;
      employees.push(employee);
    });
    console.log(employees);
    form.resetFields();
    setNewEmployees(employees);
    setModal(false);
    setConfirm(true);
  };

  const downloadLayoutExcel = (col, filename) => {
    const workbook = new EXCELJS.Workbook();
    const worksheet = workbook.addWorksheet('Clientes');
    worksheet.addRow(col);
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });
      fileSaver(blob, `${filename}.xlsx`);
    });
  };

  return (
    <>
      <Button
        onClick={() => setModal(true)}
        type="primary"
        style={{ marginLeft: '1em' }}
      >
        Cargar Movimientos
      </Button>
      <Modal
        onCancel={() => setConfirm(false)}
        onOk={async () => {
          const oResponse = await process(SAVE, 'movements', new_employees);
          if (oResponse?.ok) {
            message.success('Cargados correctamente');
            setConfirm(false);
          } else {
            message.error('Hubo un error al cargar');
          }
        }}
        visible={confirm}
      >
        <p>
          Se van a subir {new_employees.length} movimientos, ¿Desea continuar?
        </p>
      </Modal>

      <Modal
        title="Cargar Movimientos"
        visible={modal}
        onOk={() => form.submit()}
        onCancel={() => {
          setFileList([]);
          setModal(false);
          form.resetFields();
        }}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item label="Cuenta" name="account_id">
            <Select loading={loading}>
              {account.data.map(oAccount => (
                <Select.Option key={oAccount._id} value={oAccount._id}>
                  {oAccount.account_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Cliente" name="client_id">
            <Select loading={loading2}>
              {clients.data.map(client => (
                <Select.Option key={client._id} value={client._id}>
                  {client.name || client.attorney || client.trade_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="file">
            <Dragger {...uploader_props} fileList={fileList}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Haga click o arrastre un archivo de excel para subirlo
              </p>
              <p className="ant-upload-hint">
                Suba un archivo de excel con los datos de los clientes
              </p>
            </Dragger>
          </Form.Item>
          <Row>
            <Button
              color="secodary"
              onClick={() =>
                downloadLayoutExcel(columns, 'Plantilla de Movimientos')
              }
            >
              Plantilla de Movimientos
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

MultipleModal.propTypes = {
  submitEmployee: PropTypes.func.isRequired,
};

export default MultipleModal;
