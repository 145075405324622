import React from 'react';
import { AccountingMovementTable } from '../../Components/AccountingMovements/AccountingMovementsTable';

export const AccountingMovementsScreen = () => {
  return (
    <div className="accounting-movements-screen">
      <AccountingMovementTable />
    </div>
  );
};
