import React from 'react';
import { AccountReceivableTable } from '../../Components/AccountsReceivable/AccountReceivableTable';

export const AccountsReceivableScreen = () => {
  return (
    <div className="account-receivable-screen">
      <AccountReceivableTable />
    </div>
  );
};
