import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  useFetchAccountingMovements,
  useFetchAccountingAccounts,
} from '../../Hooks';
import { SearchBar } from '../Atoms/SearchBar';
import { generateQueries } from '../../Utils/query';
import { aSearchElements, oInitState } from './AccountingMovementsConstants';
import dayjs from 'dayjs';

export const AccountingMovementTable = () => {
  const [search, setSearch] = useState(oInitState);
  const [movevments, loading, change] = useFetchAccountingMovements();
  const [sumAmount, setSumAmount] = useState(0);
  const [accounts] = useFetchAccountingAccounts('', 0, 1000);

  useEffect(() => {
    if (movevments?.data) {
      let sum = 0;
      movevments.data.forEach(oMovement => {
        sum += oMovement.amount;
      });
      setSumAmount(sum);
    }
  }, [movevments]);

  const columns = [
    {
      dataIndex: 'accounting_account_id',
      title: 'Cuenta',
      render: (_, row) =>
        row?.accounting_account?.name || 'Sin cuenta asignada',
    },
    {
      dataIndex: 'date',
      title: 'Fecha',
      render: date => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      dataIndex: 'amount',
      title: (
        <>
          Monto
          <br /> ${sumAmount.toLocaleString('es-MX')}
        </>
      ),
      render: amount =>
        `$${amount ? (+amount.toFixed(2)).toLocaleString('es-MX') : 0}`,
    },
    {
      dataIndex: 'concept',
      title: 'Concepto',
    },
    {
      dataIndex: 'movement_type',
      title: 'Tipo de Movimiento',
      render: type => <Tag>{type ? 'Cargo' : 'Abono'}</Tag>,
    },
    {
      dataIndex: 'office',
      title: 'Oficina',
      render: office => <Tag>{office ? office : 'N/A'}</Tag>,
    },
    {
      dataIndex: 'reference',
      title: 'Referencia',
      render: reference => (reference ? reference : 'N/A'),
    },
  ];

  const _handleReset = () => {
    setSearch(oInitState);
    change();
  };

  const _handleSearch = () =>
    change(generateQueries(search, aSearchElements(accounts.data)));

  return (
    <div>
      <Row justify="space-between">
        <Col className="screen-title" span={3}>
          <h3>Movimientos Contables</h3>
          <h3>
            <Tag>
              {movevments?.total ? movevments.total : <LoadingOutlined />}
            </Tag>
          </h3>
        </Col>
      </Row>
      <SearchBar
        elements={aSearchElements(accounts.data)}
        handleReset={_handleReset}
        handleSearch={_handleSearch}
        {...{ search, setSearch }}
      />
      <Table
        columns={columns}
        dataSource={movevments?.data}
        loading={loading}
        pagination={{
          current: movevments.skip / 10 + 1,
          onChange: e => change(movevments.queries, (e - 1) * 10),
          pageSizeOptions: [10],
          total: movevments.total,
        }}
        rowKey={row => row.id}
      />
    </div>
  );
};
