export const aSearchElements = () => [
  {
    label: 'Fecha',
    name: 'date',
    type: 'date',
  },
  {
    label: 'Estrategia',
    name: 'reference',
    type: 'input',
  },
  {
    label: 'Salida de dinero',
    name: 'exit',
    type: 'input',
  },
];

export const oInitState = {
  reference: '',
  exit: '',
};
