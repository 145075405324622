import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  message,
  notification,
  Popconfirm,
  Select,
  Row,
  Table,
  Tag,
} from 'antd';
import PropTypes from 'prop-types';
import {
  CheckCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  LoadingOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { SearchSelector } from '../Atoms/SearchSelector';
import { SearchBar } from '../Atoms/SearchBar';
import { aSearchElements, oInitState } from './MovementConstants';
import { process, UPDATE, SAVE } from '../../Service/Api';
import {
  useFetchMovements,
  useFetchAccounts,
  useFetchClients,
} from '../../Hooks';
import { generateQueries } from '../../Utils/query';
import { MovementActions } from './MovementActions';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import ExcelDownload from './ExcelDownloader';

import MultipleModal from '../Clients/MultipleModal';

const AcceptMovement = ({
  selected,
  setSelected,
  setVisible,
  visible,
  updater,
}) => {
  const [form] = Form.useForm();
  const [sStatus, setStatus] = useState(null);
  const [clients, loading, query] = useFetchClients('', 0, 50);

  const clientSearch = [
    {
      name: 'name',
      type: 'input',
    },
    {
      name: 'status',
      type: 'select',
    },
  ];

  const handleSearchClients = s => {
    if (s.length > 3) {
      let sQueries = generateQueries({ name: s, status: 1 }, clientSearch);
      query(sQueries, 0, 50);
    } else {
      query();
    }
  };

  const onSubmit = async values => {
    const oSend = {};
    if (sStatus) {
      oSend.status = sStatus;
    }
    if (values.client_id) {
      oSend.client_id = values.client_id;
    }

    const response = await process(UPDATE, 'movements', oSend, {
      id: selected._id,
    });

    if (response?.ok) {
      message.success('Actualizado correctamente');
      updater();
      setVisible(false);
      setSelected({});
    } else {
      message.error('Error al actualizar el estatus del movimiento');
    }
    form.resetFields();
  };

  return (
    <Modal
      centered
      destroyOnClose
      width={400}
      cancelText="Cancelar"
      okText="Aceptar"
      onCancel={() => {
        setVisible(false);
        setSelected({});
        setStatus(null);
        form.resetFields();
      }}
      onOk={() => form.submit()}
      title={'Estatus del movimento'}
      visible={visible}
    >
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item label="Estatus" name="status">
              <Select
                onChange={e => setStatus(e)}
                defaultValue={selected.status}
              >
                <Select.Option value={2}>Concliado</Select.Option>
                <Select.Option value={1}>Pagado</Select.Option>
                <Select.Option value={0}>Pendiente</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item label="Cliente" name="client_id">
              <SearchSelector
                allowClear
                handleSearch={debounce(handleSearchClients, 300)}
                loading={loading}
                onChange={() => query()}
                placeholder="Seleccionar cliente"
                defaultValue={selected.client_id}
              >
                {clients?.data.map(oClient => (
                  <Select.Option key={oClient._id} value={oClient._id}>
                    {oClient.name || oClient.attorney || oClient.trade_name}
                  </Select.Option>
                ))}
              </SearchSelector>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const MovementTable = () => {
  const [search, setSearch] = useState(oInitState);
  const [movements, loading, change, updater] = useFetchMovements();
  const [clients] = useFetchClients('', 0, 1000);
  const [accounts] = useFetchAccounts();
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [sumDeposit, setSumDeposit] = useState(0);
  const [sumWithdrawal, setSumWithdrawal] = useState(0);
  const [sumBalance, setSumBalance] = useState(0);
  const [selected, setSelected] = useState({});
  const [visible, setVisible] = useState(false);
  const { user } = useSelector(state => ({ user: state.auth.user }));

  const fnSearchElements = useMemo(() => {
    return aSearchElements(accounts?.data, clients?.data);
  });
  const [formRef] = Form.useForm();

  const handleDownloadPDF = async () => {
    const hideMessage = message.loading('Generando reporte...', 0, () => {
      message.success('Reporte generado correctamente');
    });
    process('POST', 'monthly-report')
      .then(response => {
        hideMessage();
        const { file } = response.data;
        const linkSource = `data:application/pdf;base64,${file}`;
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);

        downloadLink.href = linkSource;
        downloadLink.target = '_self';
        downloadLink.download = 'reporte.pdf';
        downloadLink.click();
      })
      .catch(err => {
        hideMessage();
        console.log(err);
        message.error('Error al generar reporte');
      });
  };

  const expandedRowRender = record => {
    if (!record.sub_movements) {
      return expandSubMovements(record);
    }

    const aColumnsSubMovements = [
      {
        dataIndex: 'date',
        title: 'Fecha',
        render: oDate => oDate.toString().substring(0, 10),
      },
      {
        dataIndex: 'client_id',
        title: 'Cliente',
        render: client_id => {
          const client = clients?.data.find(
            oClient => oClient._id === client_id
          );
          return client?.name || client?.attorney || client?.trade_name;
        },
      },
      {
        dataIndex: 'withdrawal',
        title: 'Retiro',
        render: withdrawal =>
          withdrawal ? `$${withdrawal.toLocaleString('en-US')}` : null,
      },
      {
        dataIndex: 'deposit',
        title: 'Depósito',
        render: deposit =>
          deposit ? `$${(+deposit.toFixed(2)).toLocaleString('en-US')}` : null,
      },
      {
        dataIndex: 'office_commission',
        title: (
          <>
            Comisión <br /> Oficina
          </>
        ),
        render: office_commission =>
          office_commission
            ? `$${(+office_commission.toFixed(2)).toLocaleString('en-US')}`
            : null,
      },
      {
        dataIndex: 'executive_amount',
        title: (
          <>
            Comisión <br /> Ejecutivos
          </>
        ),
        render: office_commission =>
          office_commission
            ? `$${(+office_commission.toFixed(2)).toLocaleString('en-US')}`
            : null,
      },
      {
        dataIndex: 'intermediary_amount',
        title: (
          <>
            Comisión <br /> Intermediarios
          </>
        ),
        render: comissionIntermediaries =>
          comissionIntermediaries
            ? `$${(+comissionIntermediaries.toFixed(2)).toLocaleString(
                'en-US' // eslint-disable-line
              )}` // eslint-disable-line
            : null,
      },
      {
        dataIndex: 'extra',
        title: <>Retorno</>,
        render: extra => {
          return extra
            ? `$${(+extra.toFixed(2)).toLocaleString('en-US')}`
            : null;
        },
      },
      {
        dataIndex: 'balance',
        title: 'Saldo',
        render: deposit => {
          return deposit
            ? `$${(+deposit.toFixed(2)).toLocaleString('en-US')}`
            : null;
        },
      },
      {
        dataIndex: 'description',
        title: 'Descripción',
      },
      {
        dataIndex: 'account_id',
        title: 'Cuenta',
        render: account_id => {
          const account = accounts?.data.find(
            oAccount => oAccount._id === account_id
          );
          return account?.account_name;
        },
      },
      {
        dataIndex: 'status',
        title: 'Estatus',
        render: status =>
          status === 0 ? (
            <Tag color="#f50">Pendiente</Tag>
          ) : status === 1 ? (
            <Tag color="#87d068">Pagado</Tag>
          ) : status === 2 ? (
            <Tag color="#2db7f5">Conciliado</Tag>
          ) : (
            <Tag color="#ccc">Desconocido</Tag>
          ),
      },
    ];

    if (record.sub_movements && record.sub_movements.length > 0) {
      return (
        <>
          <Table
            columns={aColumnsSubMovements}
            dataSource={record.sub_movements}
            expandable={{ expandedRowRender }}
            size="small"
          />
          {expandSubMovements(record)}
        </>
      );
    }
  };

  const expandSubMovements = record => {
    if (!record?.accounting_movements?.length) {
      record.accounting_movements = [record.accounting_movements];
    }

    const columns = [
      {
        dataIndex: 'concept',
        title: 'Concepto',
        key: 'concept',
      },
      {
        dataIndex: 'amount',
        title: 'Monto',
        key: 'amount',
        render: amount =>
          amount ? `$${(+amount.toFixed(2)).toLocaleString('en-US')}` : 0.0,
      },
    ];
    if (!record.accounting_movements) {
      return null;
    }

    return (
      <Table
        columns={columns}
        dataSource={record.accounting_movements}
        pagination={false}
        size="small"
      />
    );
  };
  const aColumns = [
    {
      dataIndex: 'withdrawal',
      title: (
        <>
          Retiro
          <br />${sumWithdrawal.toLocaleString('es-MX')}
        </>
      ),
      render: withdrawal =>
        withdrawal ? `$${withdrawal.toLocaleString('en-US')}` : null,
    },
    {
      dataIndex: 'deposit',
      title: (
        <>
          Depósito
          <br />${sumDeposit.toLocaleString('es-MX')}
        </>
      ),
      render: deposit =>
        deposit ? `$${(+deposit.toFixed(2)).toLocaleString('en-US')}` : null,
    },
    {
      dataIndex: 'office_commission',
      title: (
        <>
          Comisión <br /> Oficina
        </>
      ),
      render: office_commission =>
        office_commission
          ? `$${(+office_commission.toFixed(2)).toLocaleString('en-US')}`
          : null,
    },
    {
      dataIndex: 'executive_amount',
      title: (
        <>
          Comisión <br /> Ejecutivos
        </>
      ),
      render: office_commission =>
        office_commission
          ? `$${(+office_commission.toFixed(2)).toLocaleString('en-US')}`
          : null,
    },
    {
      dataIndex: 'intermediary_amount',
      title: (
        <>
          Comisión <br /> Intermediarios
        </>
      ),
      render: comissionIntermediaries =>
        comissionIntermediaries
          ? `$${(+comissionIntermediaries.toFixed(2)).toLocaleString('en-US')}`
          : null,
    },
    {
      dataIndex: 'extra',
      title: <>Retorno</>,
      render: extra => {
        return extra ? `$${(+extra.toFixed(2)).toLocaleString('en-US')}` : null;
      },
    },
    {
      dataIndex: 'balance',
      title: (
        <>
          Saldo
          <br />${sumBalance ? sumBalance.toLocaleString('es-MX') : '0'}
        </>
      ),
      render: deposit => {
        return deposit
          ? `$${(+deposit.toFixed(2)).toLocaleString('en-US')}`
          : null;
      },
    },
    {
      dataIndex: 'description',
      title: 'Descripción',
    },
    {
      dataIndex: 'account_id',
      title: 'Cuenta',
      render: account_id => {
        const account = accounts?.data.find(
          oAccount => oAccount._id === account_id
        );
        return account?.account_name;
      },
    },
    {
      dataIndex: 'office',
      title: 'Officina',
    },
    {
      dataIndex: ['updated_by_user', 'full_name'],
      title: 'Actualizado por',
    },
    {
      dataIndex: 'status',
      title: 'Estatus',
      render: status =>
        status === 0 ? (
          <Tag color="#f50">Pendiente</Tag>
        ) : status === 1 ? (
          <Tag color="#87d068">Pagado</Tag>
        ) : status === 2 ? (
          <Tag color="#2db7f5">Conciliado</Tag>
        ) : (
          <Tag color="#ccc">Desconocido</Tag>
        ),
    },
    {
      dataIndex: '_id',
      title: 'Acciones',
      render: (_, row) => {
        return (
          <Row>
            {user.rol === 'oficina' ? null : (
              <Button
                onClick={() => {
                  setSelected(row);
                  setVisible(true);
                }}
              >
                <CheckCircleTwoTone twoToneColor="#BF8A4B" />
              </Button>
            )}

            <Button
              onClick={() => {
                setSelected(row);
                formRef.setFieldsValue({
                  ...row,
                  date: new Date(row.date).toISOString().slice(0, 10),
                });
                setModal(true);
              }}
            >
              {user.rol === 'oficina' ? (
                <EyeTwoTone twoToneColor="#BF8A4B" />
              ) : (
                <EditTwoTone twoToneColor="#BF8A4B" />
              )}
            </Button>
            {user.rol === 'oficina' ? null : (
              <Popconfirm
                onConfirm={() => handleDeactivate(row._id)}
                title="Esta seguro de desactivar este movimiento?"
              >
                <Button>
                  <DeleteTwoTone twoToneColor="#BF8A4B" />
                </Button>
              </Popconfirm>
            )}
          </Row>
        );
      },
    },
  ];

  const handleReset = () => {
    setSearch(oInitState);
    change();
  };

  const handleSearch = () => change(generateQueries(search, fnSearchElements));

  const handleDeactivate = async sId => {
    const response = await process(
      UPDATE,
      'movements',
      { status: 0 },
      { id: sId }
    );
    if (response?.ok) {
      message.success('Desactivado correctamente');
      updater();
    } else {
      message.error('Error al desactivar');
    }
  };

  useEffect(() => {
    const aWithdrawals = movements?.data.filter(
      oMovement => oMovement.withdrawal > 0
    );
    const aDeposits = movements?.data.filter(
      oMovement => oMovement.deposit > 0
    );
    const nSumWithdrawal = aWithdrawals.reduce(
      (nSum, oMovement) => nSum + oMovement.withdrawal,
      0
    );
    const nSumDeposit = aDeposits.reduce(
      (nSum, oMovement) => nSum + oMovement.deposit,
      0
    );
    const nSumBalance = movements?.data.reduce(
      (nSum, oMovement) => nSum + oMovement.balance,
      0
    );
    setSumDeposit(nSumDeposit);
    setSumWithdrawal(nSumWithdrawal);
    setSumBalance(nSumBalance);
  }, [movements]);

  const handleSubmit = async values => {
    //Add movmentsMixed
    const movementsMixed = Object.entries(values).reduce(
      (result, [key, value]) => {
        if (key.startsWith('mov_amount_')) {
          const index = key.split('_')[2];
          const amount = Number(value);
          const type = Number(values[`mov_method_${index}`]);
          const account_id = values[`mov_accountId_${index}`];

          result.push({
            amount: amount,
            type: type,
            account_id: account_id,
          });

          delete values[`mov_amount_${index}`];
          delete values[`mov_method_${index}`];
          delete values[`mov_accountId_${index}`];
        }

        return result;
      },
      []
    );

    setModalLoading(true);
    let response;
    let oSend = { ...values };
    if (movementsMixed.length > 0) {
      oSend.movements_mixed = movementsMixed;
    }

    // Set default value of spei_charge to 0 (BOTH)
    if (!oSend.spei_charge) {
      oSend.spei_charge = 0;
    }

    Object.keys(oSend).forEach(key => {
      if (oSend[key] === undefined || oSend[key] === '') {
        delete oSend[key];
      }
    });

    if (oSend.balances) {
      for (let i = 0; i < oSend.balances.length; i++) {
        delete oSend.balances[i]._id;
      }
    }

    if (selected?._id) {
      response = await process(UPDATE, 'movements', oSend, {
        id: selected._id,
      });
    } else {
      response = await process(SAVE, 'movements', oSend);
    }

    setModalLoading(false);
    if (response?.ok) {
      message.success('Exito');
      formRef.resetFields();
      setSelected({});
      setModal(false);
      updater();
    } else {
      const { data } = response;
      if (data?.code === 400) {
        let sErrors = '';
        if (Array.isArray(data.errors)) {
          for (const oError of data.errors) {
            if (oError.type === 'unique violation') {
              sErrors += `El valor ${oError.path} ya existe en BD\n`;
            }
          }
        } else {
          for (const sKey in data?.errors) {
            sErrors += data.errors[sKey] + '\n';
          }
        }
        message.error(data?.message);
        if (sErrors !== '') {
          notification.error({
            message: sErrors,
            title: 'Errores',
          });
        }
      } else if (data?.code === 409) {
        message.error('Valor duplicado');
      } else {
        message.error('Error en clientes');
      }
    }
  };

  return (
    <div>
      <Row justify="space-between">
        <Col className="screen-title">
          <h3>Movimientos</h3>
          <h3 style={{ marginLeft: '1em' }}>
            <Tag>
              {movements?.total ? movements.total : <LoadingOutlined />}
            </Tag>
          </h3>
        </Col>
        <Col>
          <Button onClick={() => setModal(true)} type="primary">
            Crear
          </Button>
          <MultipleModal submitEmployee={handleSubmit} />
          <ExcelDownload search={search} />
          <Button
            type="primary"
            style={{ marginLeft: '1em' }}
            onClick={handleDownloadPDF}
          >
            Descargar reporte{' '}
          </Button>
        </Col>
      </Row>
      <AcceptMovement
        selected={selected}
        setSelected={setSelected}
        visible={visible}
        setVisible={setVisible}
        updater={updater}
      />
      <MovementActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: handleSubmit,
          visible: modal,
        }}
      />
      <SearchBar
        elements={fnSearchElements}
        {...{
          handleReset,
          handleSearch,
          search,
          setSearch,
        }}
      />
      <Table
        columns={aColumns}
        dataSource={movements?.data}
        loading={loading}
        expandable={{ expandedRowRender }}
        pagination={{
          current: movements.skip / movements.limit + 1,
          onChange: e =>
            change(
              generateQueries(
                search,
                aSearchElements(accounts?.data, clients?.data)
              ),
              (e - 1) * movements.limit
            ),
          pageSizeOptions: [10, 20],
          total: movements?.total,
        }}
        rowKey={row => row._id}
      />
    </div>
  );
};

AcceptMovement.propTypes = {
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  updater: PropTypes.func,
};
