import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import {
  AccountsScreen,
  AccountingAccountsScreen,
  AccountsReceivableScreen,
  AccountingMovementsScreen,
  BanksScreen,
  ClientsScreen,
  EmployeeScreen,
  InitialScreen,
  MovementScreen,
  ProfileScreen,
  RolesScreen,
  UsersScreen,
  UserDetailsScreen,
  StrategiesScreen,
} from '../../Screens';

const SubRouter = ({ match: { url }, locations }) => {
  return (
    <Switch>
      <Route
        component={UsersScreen}
        exact
        location={locations}
        path={`${url}/users`}
      />
      <Route
        component={RolesScreen}
        exact
        location={locations}
        path={`${url}/roles`}
      />
      <Route
        component={InitialScreen}
        exact
        location={locations}
        path={`${url}`}
      />
      <Route component={AccountsScreen} exact path={`${url}/accounts`} />
      <Route
        component={AccountingAccountsScreen}
        exact
        location={locations}
        path={`${url}/accounting-accounts`}
      />
      <Route
        component={AccountsReceivableScreen}
        exact
        location={locations}
        path={`${url}/accounts-receivable`}
      />
      <Route
        component={AccountingMovementsScreen}
        exact
        location={locations}
        path={`${url}/accounting-movements`}
      />
      <Route
        component={BanksScreen}
        exact
        location={locations}
        path={`${url}/banks`}
      />
      <Route
        component={ClientsScreen}
        exact
        location={locations}
        path={`${url}/clients`}
      />
      <Route
        component={EmployeeScreen}
        exact
        location={locations}
        path={`${url}/employees`}
      />
      <Route
        component={MovementScreen}
        exact
        location={locations}
        path={`${url}/movements`}
      />
      <Route
        component={ProfileScreen}
        exact
        location={locations}
        path={`${url}/profile`}
      />
      <Route
        component={UserDetailsScreen}
        exact
        name="Detalle Usuario"
        path={`${url}/user-details/:id`}
      />
      <Route
        component={StrategiesScreen}
        exact
        name="Estrategias fiscales"
        path={`${url}/strategies`}
      />
    </Switch>
  );
};

SubRouter.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  locations: PropTypes.object,
};

export default withRouter(SubRouter);
