import { Descriptions, Tabs, Tag, Table, Tree } from 'antd';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchClients } from '../../Hooks/Client.hook';
import { useFetchBank } from '../../Hooks/Bank.hook';
import {
  solicitudesColumns,
  solicitudesData,
  MovimientosColumns,
  MovimientosData,
  ContratosData,
} from './ClientDetailsConstants';

export const ClientDetailsTable = () => {
  const { id } = useParams();
  const [clients, loading, ,] = useFetchClients();
  const [client, setClient] = useState({});
  const [bank] = useFetchBank();

  useEffect(() => {
    if (!loading) {
      setClient(clients?.data.find(({ _id }) => _id === id));
    }
  }, [loading]);

  const solicitudesTable = () => {
    return (
      <Table columns={solicitudesColumns} dataSource={solicitudesData}></Table>
    );
  };

  const movimientosTable = () => {
    return (
      <Table columns={MovimientosColumns} dataSource={MovimientosData}></Table>
    );
  };

  return (
    <div>
      <Descriptions title="Detalles Cliente" loading={loading}>
        <Descriptions.Item label="Nombre"> {client?.name}</Descriptions.Item>
        <Descriptions.Item label="Abogado">
          {client?.attorney}
        </Descriptions.Item>
        <Descriptions.Item label="Razón Social">
          {client?.trade_name}
        </Descriptions.Item>
        <Descriptions.Item label="RFC">{client?.rfc}</Descriptions.Item>
        <Descriptions.Item label="Cuenta">{client?.account}</Descriptions.Item>
        <Descriptions.Item label="Banco">{client?.bank}</Descriptions.Item>
        <Descriptions.Item label="Compañia">
          {bank?.data.find(({ _id }) => _id === client?.company_id)?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Estatus">
          {client?.status === 1 && <Tag color="#87d068">Activo</Tag>}
          {client?.status === 0 && <Tag color="#f50">Inactivo</Tag>}
        </Descriptions.Item>
      </Descriptions>
      <Tabs defaultActiveKey="1" type="card">
        <Tabs.TabPane tab="Solicitudes de adelanto" key="1">
          {solicitudesTable()}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Movimientos" key="2">
          {movimientosTable()}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Contratos" key="3">
          <Tree treeData={ContratosData}></Tree>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
