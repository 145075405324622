import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Select, Tooltip } from 'antd';

export const EmployeeForm = ({ formRef, onSubmit }) => {
  return (
    <Form
      form={formRef}
      layout="vertical"
      name="ClientForm"
      onFinish={onSubmit}
    >
      <Row gutter={[8, 8, 8]}>
        <Col span={8}>
          <Form.Item
            label="Nombre(s)"
            name="name"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Apellido Paterno"
            name="last_name"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Apellido Materno"
            name="mothers_name"
            rules={[{ required: false, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8, 8]}>
        <Col span={8}>
          <Form.Item label="Estatus" name="status">
            <Select>
              <Select.Option value={0}>Inactivo</Select.Option>
              <Select.Option value={1}>Activo</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Tipo de empleado" name="type" initialValue={0}>
            <Select>
              <Select.Option value={2}>
                <Tooltip title="No recibe comisión" placement="left">
                  Ejecutivo A
                </Tooltip>
              </Select.Option>
              <Select.Option value={0}>
                <Tooltip
                  title="Cobra sobre el total de la oficina"
                  placement="left"
                >
                  Ejecutivo B
                </Tooltip>
              </Select.Option>

              <Select.Option value={1}>
                <Tooltip
                  title="Cobra sobre el total, subtotal o el retorno"
                  placement="left"
                >
                  Intermediario A
                </Tooltip>
              </Select.Option>

              <Select.Option value={3}>
                <Tooltip
                  title="Cobra un porcentaje de la comisión de la oficina"
                  placement="left"
                >
                  Intermediario B
                </Tooltip>
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Oficina" name="office" initialValue={'ALIQUANTUM'}>
            <Select>
              <Select.Option value={'ALIQUANTUM'}>ALIQUANTUM</Select.Option>
              <Select.Option value={'CUMBRES'}>CUMBRES</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

EmployeeForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
