import React from 'react';
import { Select } from 'antd';

export const aSearchElements = (aExecutives = []) => [
  {
    label: 'Nombre/Razon Social',
    name: 'name,trade_name',
    or: true,
    placeholder: 'Nombre',
    type: 'input',
  },
  {
    label: 'RFC',
    name: 'rfc',
    or: true,
    placeholder: 'Busqueda por RFC',
    type: 'input',
  },
  {
    label: 'Estatus',
    name: 'status',
    placeholder: 'Todos',
    type: 'select',
    values: () => (
      <>
        <Select.Option value={1}>Activo</Select.Option>
        <Select.Option value={0}>Inactivo</Select.Option>
      </>
    ),
  },
  {
    label: 'Tipo de persona',
    name: 'person_type',
    placeholder: 'Todos',
    type: 'select',
    values: () => (
      <>
        <Select.Option value={'F'}>Fisica</Select.Option>
        <Select.Option value={'M'}>Moral</Select.Option>
      </>
    ),
  },
  {
    label: 'Ejecutivos',
    name: 'employees.employee_id',
    placeholder: 'Todos',
    type: 'select-multiple',
    mode: 'multiple',
    values: () => (
      <>
        {aExecutives.map(oExecutive =>
          oExecutive?.type === 0 || oExecutive?.type === 2 ? (
            <Select.Option key={oExecutive._id} value={oExecutive._id}>
              {oExecutive?.name}
            </Select.Option>
          ) : null
        )}
      </>
    ),
  },
  {
    label: 'Intermediarios',
    name: 'intermediaries.employee_id',
    placeholder: 'Todos',
    type: 'select-multiple',
    mode: 'multiple',
    values: () => (
      <>
        {aExecutives.map(oExecutive =>
          oExecutive?.type === 1 || oExecutive?.type === 3 ? (
            <Select.Option key={oExecutive._id} value={oExecutive._id}>
              {oExecutive?.name}
            </Select.Option>
          ) : null
        )}
      </>
    ),
  },
];

export const oInitState = {
  name: '',
  rfc: '',
  status: undefined,
  person_type: undefined,
};
