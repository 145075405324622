import React from 'react';
import { ClientDetailsTable } from '../../Components/ClientDetails/ClientDetailsTable';

export const UserDetailsScreen = () => {
  return (
    <div className="user-details-screen">
      <ClientDetailsTable />
    </div>
  );
};
