import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
} from 'antd';
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from '@ant-design/icons';
import { DatePicker } from '../Atoms/DatePicker';
import { SearchSelector } from '../Atoms/SearchSelector';
import { useFetchAccountingAccounts, useFetchClients } from '../../Hooks';
import { generateQueries } from '../../Utils/query';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';

// ? https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);

const genericSearch = [
  {
    name: 'status',
    type: 'select',
  },
];

const IVA = 0.16;

const { Option } = Select;

export const AccountReceivableForm = ({ formRef, onSubmit }) => {
  const [accounts, accountsLoading, accountsQuery] =
    useFetchAccountingAccounts('status=1');
  const [clients, clientsLoading, clientsQuery] = useFetchClients('status=1');
  const [aDetails, setDetails] = useState([]);
  const nSubtotal = useMemo(() => {
    let oSubtotal = parseFloat(
      aDetails.reduce((acc, { subtotal }) => acc + subtotal, 0)
    );
    oSubtotal = Math.round((oSubtotal + Number.EPSILON) * 100) / 100;
    return +oSubtotal;
  });
  const nTotal = useMemo(() => {
    let total_order = parseFloat(
      aDetails.reduce((acc, { total }) => acc + total, 0)
    );
    total_order = Math.round((total_order + Number.EPSILON) * 100) / 100;
    return +total_order;
  });
  const [detailsForm] = Form.useForm();

  const handleSearchAccount = s => {
    if (s.length >= 3) {
      const sQueries = generateQueries({ name: s, status: 1 }, genericSearch);
      accountsQuery(sQueries, 0, 50);
    } else {
      accountsQuery();
    }
  };

  const handleSearchClient = s => {
    if (s.length >= 3) {
      const sQueries = generateQueries({ name: s, status: 1 }, genericSearch);
      clientsQuery(sQueries, 0, 50);
    } else {
      clientsQuery();
    }
  };

  const handleAddDetail = detail => {
    const subtotal =
      Math.round(
        (+(detail.unit_price * parseFloat(detail.quantity)) + Number.EPSILON) *
          100
      ) / 100;
    let aTmp = [
      ...aDetails,
      {
        id: aDetails.length,
        ...detail,
        unit_price: +detail.unit_price,
        subtotal,
        total:
          Math.round((+(subtotal * (1 + IVA)) + Number.EPSILON) * 100) / 100,
      },
    ];
    setDetails(aTmp);
    detailsForm.resetFields();
  };

  const handleEdit = index => {
    const detail = aDetails[index];
    detailsForm.setFieldsValue(detail);
    setDetails(aDetails.filter((_, i) => i !== index));
  };

  const handleRemove = index => {
    setDetails(aDetails.filter((_, i) => i !== index));
  };

  const columns = [
    {
      dataIndex: 'description',
      title: 'Detalle',
    },
    {
      dataIndex: 'quantity',
      title: 'Cantidad',
    },
    {
      dataIndex: 'unit_price',
      title: 'Precio',
      render: p => `$${p.toFixed(2)}`,
    },
    {
      dataIndex: 'subtotal',
      title: 'Subtotal',
      render: s => `$${s}`,
    },
    {
      dataIndex: 'total',
      title: 'Total',
      render: t => `$${t}`,
    },
    {
      dataIndex: 'id',
      title: 'Acciones',
      render: (...args) => (
        <Row>
          <Col>
            <Button onClick={() => handleEdit(args.pop())}>
              <EditTwoTone twoToneColor="#BF8A4B" />
            </Button>
            <Popconfirm
              onConfirm={() => handleRemove(args.pop())}
              title={`¿Está seguro de quitarlo?`}
            >
              <Button>
                <DeleteTwoTone twoToneColor="#BF8A4B" />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Form
        form={formRef}
        layout="vertical"
        name="AccountReceivableForm"
        onFinish={() => {
          onSubmit({
            ...formRef.getFieldsValue(),
            iva: nTotal - nSubtotal,
            sub_total: nSubtotal,
            total: nTotal,
            details: aDetails,
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item label="Cuenta" name="account_id">
              <SearchSelector
                handleSearch={debounce(handleSearchAccount, 300)}
                loading={accountsLoading}
                placeholder="Buscar Cuenta"
              >
                {accounts?.data.map(oAccount => (
                  <Select.Option key={oAccount._id} value={oAccount._id}>
                    {oAccount.name} - {oAccount.account}
                  </Select.Option>
                ))}
              </SearchSelector>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Cliente" name="client_id">
              <SearchSelector
                handleSearch={debounce(handleSearchClient, 300)}
                loading={clientsLoading}
                placeholder="Buscar Cliente"
              >
                {clients?.data.map(oClient => (
                  <Select.Option key={oClient._id} value={oClient._id}>
                    {oClient.name} {oClient.last_name}
                  </Select.Option>
                ))}
              </SearchSelector>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <Form.Item label="Fecha" name="date" initialValue={dayjs()}>
              <DatePicker format={'DD-MM-YYYY'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Fecha de pago"
              name="payment_date"
              initialValue={dayjs()}
            >
              <DatePicker format={'DD-MM-YYYY'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Fecha de Expiración" name="expiration_date">
              <DatePicker format={'DD-MM-YYYY'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Referencia" name="reference">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Estatus" name="status" initialValue={1}>
              <Select>
                <Option value={1}>Activo</Option>
                <Option value={0}>Inactivo</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Comentarios" name="comments">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider>Detalles</Divider>
      <Form
        form={detailsForm}
        layout="vertical"
        onFinish={() => {
          const formData = detailsForm.getFieldsValue(true);
          handleAddDetail({
            ...formData,
            quantity: formData.quantity > 0 ? formData.quantity : 1,
          });
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item
              label="Detalle"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Ingresa el detalle',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Cantidad" name="quantity" initialValue={1}>
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Precio"
              name="unit_price"
              rules={[
                {
                  required: true,
                  message: 'Ingresa el precio del detalle',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label=" ">
              <Button onClick={detailsForm.submit}>
                <PlusOutlined /> Añadir
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {aDetails.length ? (
        <>
          <Row>
            <Table
              columns={columns}
              dataSource={aDetails}
              style={{ width: '100%' }}
              pagination={{ defaultPageSize: 5 }}
            />
          </Row>
          <Row justify="space-between">
            <Col span={6} className="totals">
              Subtotal:{' '}
            </Col>
            <Col span={6} className="totals">
              $ {nSubtotal}
            </Col>
            <Col span={6} className="totals">
              Total:{' '}
            </Col>
            <Col span={6} className="totals">
              $ {nTotal}
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

AccountReceivableForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
