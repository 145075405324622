import React from 'react';
import {
  BankOutlined,
  CreditCardOutlined,
  DollarCircleOutlined,
  FileDoneOutlined,
  HomeOutlined,
  ReconciliationOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

export const Routes = [
  {
    title: 'Home',
    slug: 'home',
    path: '/',
    icon: <HomeOutlined className="anticon" />,
    roles: ['admin', 'superadmin', 'test', 'client', 'estrategias'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Bancos',
    slug: 'banks',
    path: '/banks',
    icon: <BankOutlined className="anticon" />,
    roles: ['admin', 'superadmin', 'test'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Cuentas',
    slug: 'accounts',
    path: '/accounts',
    icon: <CreditCardOutlined className="anticon" />,
    roles: ['admin', 'superadmin', 'test', 'oficina'],
    childs: [],
  },
  {
    title: 'Cuentas Contables',
    slug: 'accounting-accounts',
    path: '/accounting-accounts',
    icon: <ReconciliationOutlined className="anticon" />,
    roles: ['admin', 'test', 'superadmin'],
    childs: [],
  },
  {
    title: 'Cuentas Por Cobrar',
    slug: 'accounts-receivable',
    path: '/accounts-receivable',
    icon: <DollarCircleOutlined className="anticon" />,
    roles: ['admin', 'test', 'superadmin'],
    childs: [],
  },
  {
    title: 'Ejecutivos',
    slug: 'employees',
    path: '/employees',
    icon: <UsergroupAddOutlined className="anticon" />,
    roles: ['admin', 'test', 'superadmin'],
    childs: [],
  },
  {
    title: 'Clientes',
    slug: 'clients',
    path: '/clients',
    icon: <UsergroupDeleteOutlined className="anticon" />,
    roles: ['admin', 'test', 'oficina', 'superadmin'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Movimientos',
    slug: 'movements',
    path: '/movements',
    icon: <FileDoneOutlined className="anticon" />,
    roles: ['admin', 'test', 'oficina', 'superadmin'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Movimientos Contables',
    slug: 'accounting-movements',
    path: '/accounting-movements',
    icon: <FileDoneOutlined className="anticon" />,
    roles: ['admin', 'test', 'oficina', 'superadmin'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Estrategias',
    slug: 'strategies',
    path: '/strategies',
    icon: <FileDoneOutlined className="anticon" />,
    roles: ['superadmin', 'estrategias'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Roles',
    slug: 'roles',
    path: '/roles',
    icon: <HomeOutlined className="anticon" />,
    roles: ['test', 'superadmin'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Usuarios',
    slug: 'users',
    path: '/users',
    icon: <HomeOutlined className="anticon" />,
    roles: ['test', 'superadmin'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Perfil',
    slug: 'profile',
    path: '/profile',
    icon: <UserOutlined className="anticon" />,
    roles: ['admin', 'company', 'user', 'test', 'client', 'superadmin'],
    childs: [],
    optionalIcon: true,
  },
];
