import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { FIND, process } from '../Service/Api';

export const useFetchAccountReceivable = (
  queries = '',
  skip = 0,
  limit = 10
) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ queries, limit, skip });

  const getAccountReceivable = useCallback(async () => {
    setLoading(true);
    const response = await process(FIND, 'account-receivable', {}, params);
    if (response?.ok) {
      setData(response.data);
    } else {
      message.error('Error al consultar cuentas por cobrar');
    }
    setLoading(false);
  }, [process, params]);

  useEffect(() => {
    getAccountReceivable();
  }, [getAccountReceivable]);

  const onChangeParams = (q = '', s = 0, l = 10) =>
    setParams({ queries: q, limit: l, skip: s });

  const update = () => getAccountReceivable();

  return [data, loading, onChangeParams, update];
};
