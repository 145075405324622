import React from 'react';
import { AccountingAccountTable } from '../../Components/AccountingAccounts/AccountingAccountsTable';

export const AccountingAccountsScreen = () => {
  return (
    <div className="accounting-account-scren">
      <AccountingAccountTable />
    </div>
  );
};
