import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  message,
  notification,
  Popconfirm,
  Row,
  Table,
  Tag,
} from 'antd';
import { DeleteTwoTone, EditTwoTone, LoadingOutlined } from '@ant-design/icons';
import {
  useFetchAccountReceivable,
  useFetchAccountingAccounts,
  useFetchClients,
} from '../../Hooks';
import { AccountReceivableActions } from './AccountReceivableActions';
import { process, SAVE, UPDATE } from '../../Service/Api';
import { SearchBar } from '../Atoms/SearchBar';
import { generateQueries } from '../../Utils/query';
import { aSearchElements, oInitState } from './AccountReceivableConstants';
import dayjs from 'dayjs';

export const AccountReceivableTable = () => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [search, setSearch] = useState(oInitState);
  const [accounts, loading, change, updater] = useFetchAccountReceivable();
  const [clients] = useFetchClients();
  const [accountingAccounts] = useFetchAccountingAccounts();
  const [formRef] = Form.useForm();

  const _handleDeactivate = async sId => {
    const response = await process(
      UPDATE,
      'account-receivable',
      { status: 0 },
      { id: sId }
    );
    if (response?.ok) {
      message.success('Desactivado correctamente');
      updater();
    } else {
      message.error('Error al desactivar');
    }
  };

  const columns = [
    {
      dataIndex: 'account_id',
      title: 'Cuenta',
      render: (_, row) =>
        row.accounting_account
          ? row.accounting_account.name
          : 'Sin cuenta asignada',
    },
    {
      dataIndex: 'client_id',
      title: 'Cliente',
      render: (_, row) =>
        row.client
          ? `${row.client.name} ${row.client.last_name}`
          : 'Sin cliente asignado',
    },
    {
      dataIndex: 'iva',
      title: 'IVA',
    },
    {
      dataIndex: 'ieps',
      title: 'IEPS',
    },
    {
      dataIndex: 'subtotal',
      title: 'Subtotal',
    },
    {
      dataIndex: 'total',
      title: 'Total',
    },
    {
      dataIndex: 'comission',
      title: 'Comisión',
    },
    {
      dataIndex: 'date',
      title: 'Fecha',
      render: date => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      dataIndex: 'payment_date',
      title: 'Fecha de Pago',
      render: pDate => dayjs(pDate).format('DD/MM/YYYY'),
    },
    {
      dataIndex: 'expiration_date',
      title: 'Fecha de Vencimiento',
      render: expDate => dayjs(expDate).format('DD/MM/YYYY'),
    },
    {
      dataIndex: 'created_at',
      title: 'Creado por.',
    },
    {
      dataIndex: 'status',
      title: 'Estatus',
      render: status =>
        status ? (
          <Tag color="#87d068">Activo</Tag>
        ) : (
          <Tag color="#f50">Inactivo</Tag>
        ),
    },
    {
      dataIndex: '_id',
      title: 'Acciones',
      render: (_, row) => {
        return (
          <Row>
            <Col>
              <Button
                onClick={() => {
                  setSelected(row);
                  ['date', 'payment_date', 'expiration_date'].forEach(key => {
                    row[key] = dayjs(row[key]);
                  });
                  formRef.setFieldsValue(row);
                  setModal(true);
                }}
              >
                <EditTwoTone twoToneColor="#BF8A4B" />
              </Button>
              <Popconfirm
                onConfirm={() => _handleDeactivate(row._id)}
                title="Está seguro de desactivar esta cuenta por cobrar?"
              >
                <Button>
                  <DeleteTwoTone twoToneColor="#BF8A4B" />
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  const _handleReset = () => {
    setSearch(oInitState);
    change();
  };

  const _handleSearch = () =>
    change(
      generateQueries(
        search,
        aSearchElements(accountingAccounts.data, clients.data)
      )
    );

  const _handleSubmit = async values => {
    setModalLoading(true);
    let response;
    if (selected?._id) {
      response = await process(UPDATE, 'account-receivable', values, {
        id: selected._id,
      });
    } else {
      response = await process(SAVE, 'account-receivable', values);
    }
    if (response?.ok) {
      message.success('Exito');
      formRef.resetFields();
      setSelected({});
      setModal(false);
      updater();
    } else {
      const { data } = response;
      if (data?.code === 400) {
        let sErrors = '';
        if (Array.isArray(data.errors)) {
          for (const oError of data.errors) {
            if (oError.type === 'unique violation') {
              sErrors += `El valor ${oError.path} ya existe en BD\n`;
            }
          }
        } else {
          for (const sKey in data?.errors) {
            sErrors += data.errors[sKey] + '\n';
          }
        }
        message.error(data?.message);
        if (sErrors !== '') {
          notification.error({
            message: sErrors,
            title: 'Errores',
          });
        }
      } else if (data?.code === 409) {
        message.error('Valor duplicado');
      } else {
        message.error('Error en cuenta por cobrar');
      }
    }
    setModalLoading(false);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col className="screen-title" span={3}>
          <h3>Cuentas por cobrar</h3>
          <h3>
            <Tag>{accounts?.total ? accounts.total : <LoadingOutlined />}</Tag>
          </h3>
        </Col>
        <Col>
          <Button onClick={() => setModal(true)} type="primary">
            Crear
          </Button>
        </Col>
      </Row>
      <AccountReceivableActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected: selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: _handleSubmit,
          visible: modal,
        }}
      />
      <SearchBar
        elements={aSearchElements(accountingAccounts.data, clients.data)}
        handleReset={_handleReset}
        handleSearch={_handleSearch}
        {...{ search, setSearch }}
      />
      <Table
        columns={columns}
        dataSource={accounts?.data}
        loading={loading}
        pagination={{
          current: accounts.skip / 10 + 1,
          onChange: e => change(accounts.queries, (e - 1) * 10),
          pageSizeOptions: [10],
          total: accounts.total,
        }}
        rowKey={row => row.id}
      />
    </div>
  );
};
