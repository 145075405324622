import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Tooltip,
  Button,
  Tag,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SearchSelector } from '../Atoms/SearchSelector';
import { useFetchEmployees } from '../../Hooks';
import { generateQueries } from '../../Utils/query';
import debounce from 'lodash/debounce';

const employeeSearch = [
  {
    name: 'name',
    type: 'input',
  },
  {
    name: 'status',
    type: 'select',
  },
];

export const ClientForm = ({ formRef, onSubmit }) => {
  const [type, setType] = useState('F');
  const [platformPay, setPlatformPay] = useState(false);
  const [adminPay, setAdminPay] = useState(false);
  const [floor_4, setFloor_4] = useState(false);
  const [direct, setDirect] = useState(false);
  const [employees, loading, query] = useFetchEmployees('', 0, 100);
  const [employeesAll, loadingAll] = useFetchEmployees('', 0, 500);
  const [companies, setCompanies] = useState([]);
  const [companyCurrent, setCompanyCurrent] = useState('');
  const [executives, setExecutives] = useState([]);
  const [intermediaries, setIntermediary] = useState([]);
  const personType = formRef.getFieldValue('person_type');

  useEffect(() => {
    if (personType) {
      setType(personType);
    }
  }, [personType]);

  useEffect(() => {
    setPlatformPay(formRef.getFieldValue('platform_pay') || false);
    setAdminPay(formRef.getFieldValue('pay_administrative') || false);
    setFloor_4(formRef.getFieldValue('floor_4') || false);
    setDirect(formRef.getFieldValue('direct') || false);
    setExecutives(formRef.getFieldValue('employees') || []);
    setIntermediary(formRef.getFieldValue('intermediaries') || []);
    setCompanies(formRef.getFieldValue('associated_companies') || []);
  }, []);

  useEffect(() => {
    if (employeesAll.data.length > 0) {
      const employees_temp = [];
      const intermediaries_temp = [];
      executives.forEach(e => {
        const info = employeesAll.data.find(({ _id }) => _id === e.value);
        employees_temp.push({
          _id: info._id,
          name: info.name,
          last_name: info.last_name,
          type: info.type,
          value: info._id,
        });
      });

      intermediaries.forEach(e => {
        const info = employeesAll.data.find(({ _id }) => _id === e.value);
        intermediaries_temp.push({
          _id: info._id,
          name: info.name,
          last_name: info.last_name,
          type: info.type,
          value: info._id,
        });
      });
      setExecutives(employees_temp);
      setIntermediary(intermediaries_temp);
    }
  }, [employeesAll]);

  const handleChangeExecutives = value => {
    const employees_temp = [];
    value.forEach(e => {
      const info = employeesAll.data.find(({ _id }) => _id === e);
      employees_temp.push({
        _id: info._id,
        name: info.name,
        last_name: info.last_name,
        type: info.type,
        value: info._id,
      });
    });
    setExecutives(employees_temp);
  };

  const handleChangeIntermediary = value => {
    const intermed_temp = [];
    value.forEach(e => {
      const info = employeesAll.data.find(({ _id }) => _id === e);
      intermed_temp.push({
        _id: info._id,
        name: info.name,
        last_name: info.last_name,
        type: info.type,
        value: info._id,
      });
    });
    setIntermediary(intermed_temp);
  };
  const handleSearchEmployees = s => {
    if (s.length > 3) {
      let sQueries = generateQueries({ name: s, status: 1 }, employeeSearch);
      query(sQueries, 0, 50);
    } else {
      query('', 0, 100);
    }
  };

  const addCompany = () => {
    if (companyCurrent.trim() !== '') {
      setCompanies([...companies, { trade_name: companyCurrent }]);
      setCompanyCurrent('');
    }
  };

  const deleteCompany = index => {
    const nuevasEmpresas = companies.filter((_, i) => i !== index);
    setCompanies(nuevasEmpresas);
  };

  const handleSubmit = values => {
    //Handler submit to embed partner companies
    const formDataWithCompanies = {
      ...values,
      associated_companies: companies,
    };
    onSubmit(formDataWithCompanies);
  };

  return (
    <Form
      form={formRef}
      layout="vertical"
      name="ClientForm"
      onFinish={handleSubmit}
    >
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Tipo de Persona"
            name="person_type"
            initialValue="F"
          >
            <Select onChange={p => setType(p)}>
              <Select.Option value="F">Fisica</Select.Option>
              <Select.Option value="M">Moral</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="RFC"
            name="rfc"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>

      {type === 'M' ? (
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item
              label="Razon Social"
              name="trade_name"
              rules={[
                {
                  required: false,
                  message: 'Campo requerido',
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Representante Legal"
              name="attorney"
              rules={[
                {
                  required: false,
                  message: 'Campo requerido',
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Row gutter={[8, 8, 8]}>
          <Col span={8}>
            <Form.Item
              label="Nombre(s)"
              name="name"
              rules={[{ required: false, message: 'Campo requerido' }]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Apellido Paterno"
              name="last_name"
              rules={[{ required: false, message: 'Campo requerido' }]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Apellido Materno"
              name="mother_name"
              rules={[{ required: false, message: 'Campo requerido' }]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item label="Empresa Asociada">
            <Input
              placeholder="Ingresa la razón social de la empresa asociada"
              value={companyCurrent}
              onChange={e => setCompanyCurrent(e.target.value)}
            />
            <Button
              onClick={addCompany}
              size="large"
              style={{ marginTop: '10px' }}
            >
              Agregar Empresa
            </Button>
          </Form.Item>
          {companies.map((company, index) => (
            <Tag key={index}>
              {company.trade_name}
              <Button
                onClick={() => deleteCompany(index)}
                danger
                type="text"
                icon={<CloseOutlined />}
              />
            </Tag>
          ))}
        </Col>
        <Col span={12}>
          <Form.Item
            label="Cuenta"
            name="account"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Estatus" name="status">
            <Select>
              <Select.Option value={0}>Inactivo</Select.Option>
              <Select.Option value={1}>Activo</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tipo de comision" name="commission_type">
            <Select placeholder={'Tipo comision'}>
              <Select.Option value={0}>Total</Select.Option>
              <Select.Option value={1}>Sub total</Select.Option>
              <Select.Option value={2}>Retorno cliente</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item
            label={
              <Tooltip title="Comisión efectivo y tranferencias">
                Comisión
              </Tooltip>
            }
            name="commission_transfer"
            rules={[{ required: true, message: 'Campo requerido' }]}
            onChange={({ target: { value } }) => {
              formRef.setFieldsValue({
                commission_asimilados: value,
              });
            }}
          >
            <Input type="number" suffix="%" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <Tooltip title="Comisión en asimilados">
                Comisión asimilados
              </Tooltip>
            }
            name="commission_asimilados"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="number" suffix="%" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="floor_4"
            valuePropName="checked"
            style={{ paddingTop: '2em' }}
          >
            <Checkbox
              checked={floor_4}
              style={{ fontSize: '14px' }}
              onChange={({ target: { checked } }) => {
                setFloor_4(checked);
              }}
            >
              Piso 4
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Tooltip title="Se cobra la comision directamente del total o subtotal">
            <Form.Item
              name="direct"
              valuePropName="checked"
              style={{ paddingTop: '2em' }}
            >
              <Checkbox
                checked={direct}
                style={{ fontSize: '14px' }}
                onChange={({ target: { checked } }) => {
                  setDirect(checked);
                }}
                disabled={!floor_4}
              >
                Comisión directa
              </Checkbox>
            </Form.Item>
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Empleado"
            name="employees"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <SearchSelector
              allowClear
              mode="multiple"
              handleSearch={debounce(handleSearchEmployees, 300)}
              loading={loading}
              onChange={value => {
                query();
                handleChangeExecutives(value);
              }}
              placeholder="Seleccionar cliente"
            >
              {employees?.data
                .filter(
                  employee =>
                    employee.status === 1 &&
                    (employee.type === 0 || employee.type === 2)
                )
                .map(oEmployee => (
                  <Select.Option key={oEmployee._id} value={oEmployee._id}>
                    {`${oEmployee.name} ${oEmployee.last_name}`}
                  </Select.Option>
                ))}
            </SearchSelector>
          </Form.Item>
        </Col>
        {executives && executives.length > 0 && !loadingAll && (
          <>
            {executives.map(employee => {
              return (
                <Col span={12} key={employee?.value}>
                  <Form.Item
                    label={
                      <Typography.Text ellipsis className="long-name">
                        {`Comisión ${employee?.name} ${employee?.last_name}`}
                      </Typography.Text>
                    }
                    name={`commission_${employee?.value}`}
                    rules={[{ required: false, message: 'Campo requerido' }]}
                  >
                    <Input
                      type="number"
                      suffix="%"
                      disabled={employee.type === 2}
                      defaultValue={employee.type === 2 ? 0 : null}
                    />
                  </Form.Item>
                </Col>
              );
            })}
          </>
        )}
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item label="Intermediario" name="intermediaries">
            <SearchSelector
              allowClear
              mode="multiple"
              handleSearch={debounce(handleSearchEmployees, 300)}
              loading={loading}
              onChange={value => {
                query();
                handleChangeIntermediary(value);
              }}
              placeholder="Seleccionar intermediario"
            >
              {employees?.data
                .filter(
                  employee =>
                    employee.status === 1 &&
                    (employee.type === 1 || employee.type === 3)
                )
                .map(oEmployee => (
                  <Select.Option key={oEmployee._id} value={oEmployee._id}>
                    {`${oEmployee.name} ${oEmployee.last_name}`}
                  </Select.Option>
                ))}
            </SearchSelector>
          </Form.Item>
        </Col>
      </Row>

      {intermediaries && intermediaries.length > 0 && !loadingAll && (
        <>
          <Row gutter={[24]} key={'commissions_intermediaries'}>
            {intermediaries.map((employee, i) => {
              return (
                <>
                  <Col span={6} key={employee?.value}>
                    <Form.Item
                      label={
                        <Typography.Text ellipsis className="long-name">
                          {`Comisión ${employee?.name} ${employee?.last_name}`}
                        </Typography.Text>
                      }
                      name={`int_commission_${employee?.value}`}
                      rules={[{ required: true, message: 'Campo requerido' }]}
                      onChange={({ target: { value } }) => {
                        formRef.setFieldsValue({
                          [`int_commission_asimilados_${employee?.value}`]:
                            value,
                        });
                      }}
                    >
                      <Input type="number" suffix="%" />
                    </Form.Item>
                  </Col>
                  <Col span={6} key={employee?.value}>
                    <Form.Item
                      label={
                        <Typography.Text ellipsis className="long-name">
                          {`Comisión asimilados`}
                        </Typography.Text>
                      }
                      name={`int_commission_asimilados_${employee?.value}`}
                      rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                      <Input type="number" suffix="%" />
                    </Form.Item>
                  </Col>
                  <Col span={12} key={i + employee?.value}>
                    <Form.Item
                      label="Tipo de comision"
                      name={`int_commission_method_${employee?.value}`}
                      rules={[{ required: true, message: 'Campo requerido' }]}
                    >
                      <Select placeholder={`Tipo de comision`}>
                        {employee?.type === 1 ? (
                          <>
                            <Select.Option value={0}>Total</Select.Option>
                            <Select.Option value={1}>Sub total</Select.Option>
                            <Select.Option value={2}>
                              Retorno cliente
                            </Select.Option>
                          </>
                        ) : (
                          <Select.Option value={0}>
                            Despues de costos
                          </Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              );
            })}
          </Row>
        </>
      )}
      <Divider>Datos de Pago</Divider>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item name="sua" label="SUA">
            <Input type="number" placeholder="0" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="account_cost" label="Costo Cuenta">
            <Input type="number" suffix="%" placeholder="0" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="lola_transfers" label="Comision Lola">
            <Input
              type="number"
              suffix="%"
              placeholder="0"
              disabled={!floor_4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="lola_asimilados" label="Asimilado Lola">
            <Input
              type="number"
              suffix="%"
              placeholder="0"
              disabled={!floor_4}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="pay_platform_percentage"
            label="Pago Plataforma"
            rules={[
              {
                required: platformPay,
                message: 'Este campo es requerido',
              },
            ]}
          >
            <Input type="number" suffix="%" placeholder="0" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="pay_administrative_percentage"
            label="Gastos Administrativos"
            rules={[
              {
                required: adminPay,
                message: 'Este campo es requerido',
              },
            ]}
          >
            <Input type="number" suffix="%" placeholder="0" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item
            name="pay_platform"
            valuePropName="checked"
            style={{ paddingTop: '1em' }}
          >
            <Checkbox
              checked={platformPay}
              style={{ fontSize: '14px' }}
              onChange={({ target: { checked } }) => {
                setPlatformPay(checked);
              }}
            >
              Pago Plataforma
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          <Form.Item
            name="pay_administrative"
            valuePropName="checked"
            style={{ paddingTop: '1em' }}
          >
            <Checkbox
              checked={adminPay}
              style={{ fontSize: '14px' }}
              onChange={({ target: { checked } }) => {
                setAdminPay(checked);
              }}
            >
              Gastos administrativos
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

ClientForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
