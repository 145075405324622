import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  message,
  notification,
  Popconfirm,
  Row,
  Table,
  Tag,
} from 'antd';
import { DeleteTwoTone, EditTwoTone, LoadingOutlined } from '@ant-design/icons';
import { SearchBar } from '../Atoms/SearchBar';
import { aSearchElements, oInitState } from './ClientConstants';
import { generateQueries } from '../../Utils/query';
import { useFetchClients, useFetchEmployees } from '../../Hooks';
import { ClientActions } from './ClientActions';
import { process, SAVE, UPDATE } from '../../Service/Api';

export const ClientTable = () => {
  const [search, setSearch] = useState(oInitState);
  const [clients, loading, change, updater] = useFetchClients();
  const [employees] = useFetchEmployees();
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [formRef] = Form.useForm();

  const aColumns = [
    {
      dataIndex: 'trade_name',
      title: 'Nombre/Razón Social',
      render: (_, { name, last_name, trade_name }) => (
        <span>
          {trade_name ? trade_name : `${name} ${last_name ? last_name : ''}`}
        </span>
      ),
    },
    {
      dataIndex: 'name',
      title: 'Representante Legal',
      render: (_, { name, last_name, attorney }) => (
        <span>{name ? `${name} ${last_name ? last_name : ''}` : attorney}</span>
      ),
    },
    {
      dataIndex: 'rfc',
      title: 'RFC',
    },
    {
      dataIndex: 'account',
      title: 'Cuenta',
    },
    {
      dataIndex: 'employees',
      title: 'Ejecutivos',
      render: (_, { employees: aEmployees }) => {
        return aEmployees.map(({ employee_id }) => (
          <Tag key={employee_id}>
            {employees?.data.find(({ _id }) => _id === employee_id)?.name}
          </Tag>
        ));
      },
    },
    {
      dataIndex: 'intermediaries',
      title: 'Intermediarios',
      render: (_, { intermediaries: aIntermediaries }) => {
        return aIntermediaries.map(({ employee_id }) => (
          <Tag key={employee_id}>
            {employees?.data.find(({ _id }) => _id === employee_id)?.name}
          </Tag>
        ));
      },
    },
    {
      dataIndex: 'person_type',
      title: 'Tipo de Persona',
      render: status =>
        status === 'M' ? (
          <Tag color="#4295f5">Moral</Tag>
        ) : (
          <Tag color="#f58442">Fisica</Tag>
        ),
    },
    {
      dataIndex: 'status',
      title: 'Estatus',
      render: status =>
        status ? (
          <Tag color="#87d068">Activo</Tag>
        ) : (
          <Tag color="#f50">Inactivo</Tag>
        ),
    },
    {
      dataIndex: ['updated_by_user', 'full_name'],
      title: 'Actualizado por',
    },
    {
      dataIndex: '_id',
      title: 'Acciones',
      render: (_, row) => {
        return (
          <Row>
            <Button
              onClick={() => {
                setSelected(row);
                const aEmployees = [];
                const commissions = {};
                const aIntermediaries = [];
                const intCommissions = {};

                row.employees.forEach(employee => {
                  const employeeTemp = {};
                  employeeTemp.label = employees?.data.find(
                    ({ _id }) => _id === employee.employee_id
                  )?.name;
                  employeeTemp.value = employee.employee_id;
                  aEmployees.push(employeeTemp);

                  commissions[`commission_${employee.employee_id}`] =
                    employee.commission;
                });

                row.intermediaries?.forEach(intermediary => {
                  const intermediaryTemp = {};
                  intermediaryTemp.label = employees?.data.find(
                    ({ _id }) => _id === intermediary.employee_id
                  )?.name;
                  intermediaryTemp.value = intermediary.employee_id;
                  aIntermediaries.push(intermediaryTemp);

                  intCommissions[`int_commission_${intermediary.employee_id}`] =
                    intermediary.commission;
                  intCommissions[
                    `int_commission_asimilados_${intermediary.employee_id}`
                  ] = intermediary.commission_asimilados;
                  intCommissions[
                    `int_commission_method_${intermediary.employee_id}`
                  ] = intermediary.commission_type;
                });

                const formValues = {
                  ...row,
                  employees: aEmployees,
                  intermediaries: aIntermediaries,
                  ...commissions,
                  ...intCommissions,
                };

                formRef.setFieldsValue(formValues);
                setModal(true);
              }}
            >
              <EditTwoTone twoToneColor="#BF8A4B" />
            </Button>
            <Popconfirm
              onConfirm={() => _handleDeactivate(row._id)}
              title="Esta seguro de desactivar este cliente?"
            >
              <Button>
                <DeleteTwoTone twoToneColor="#BF8A4B" />
              </Button>
            </Popconfirm>
          </Row>
        );
      },
    },
  ];

  const handleReset = () => {
    setSearch(oInitState);
    change();
  };

  const handleSearch = () =>
    change(generateQueries(search, aSearchElements(employees.data)));

  const handleSubmit = async values => {
    const fieldsToUpdateNull = [
      'spei',
      'sua',
      'account_cost',
      'lola_transfers',
      'lola_asimilados',
      'pay_platform_percentage',
    ];
    //Add null in the input of payments that are empty
    fieldsToUpdateNull.forEach(field => {
      if (values[field] === '') {
        values[field] = null;
      }
    });
    const aEmployees = [];
    const keysToDelete = [];
    const aIntermediary = [];

    setModalLoading(true);

    Object.keys(values).forEach(key => {
      if (
        key.includes('commission') &&
        !key.includes('type') &&
        !key.includes('trk') &&
        key !== 'commission' &&
        key !== 'commission_transfer' &&
        key !== 'commission_asimilados'
      ) {
        const id = key.split('_').pop();
        const is_intermediary = key.includes('int');
        if (is_intermediary) {
          // Check if intermediary already in array
          const index = aIntermediary.findIndex(
            intermediary => intermediary.employee_id === id
          );
          if (index === -1) {
            // If not, push it
            aIntermediary.push({
              employee_id: id,
            });
          }

          // Find intermediary in array
          const intermediary = aIntermediary.find(
            inter => inter.employee_id === id
          );

          // Update commission
          if (key.includes('method')) {
            intermediary.commission_type = values[key];
          } else if (key.includes('asimilados')) {
            intermediary.commission_asimilados = values[key];
          } else {
            intermediary.commission = values[key];
          }

          keysToDelete.push(key);
        } else {
          const employeeData = {
            employee_id: id,
            commission: values[key] || 0,
          };
          aEmployees.push(employeeData);
          keysToDelete.push(key);
        }
      }
    });

    keysToDelete.forEach(key => delete values[key]);
    delete values.employee_id;

    let response;
    let oSend = {
      ...values,
      employees: aEmployees,
      intermediaries: aIntermediary,
    };
    if (selected?._id) {
      response = await process(UPDATE, 'clients', oSend, {
        id: selected._id,
      });
    } else {
      response = await process(SAVE, 'clients', oSend);
    }
    setModalLoading(false);
    if (response?.ok) {
      message.success('Exito');
      formRef.resetFields();
      setSelected({});
      setModal(false);
      updater();
    } else {
      const { data } = response;
      if (data?.code === 400) {
        let sErrors = '';
        if (Array.isArray(data.errors)) {
          for (const oError of data.errors) {
            if (oError.type === 'unique violation') {
              sErrors += `El valor ${oError.path} ya existe en BD\n`;
            }
          }
        } else {
          for (const sKey in data?.errors) {
            sErrors += data.errors[sKey] + '\n';
          }
        }
        message.error(data?.message);
        if (sErrors !== '') {
          notification.error({
            message: sErrors,
            title: 'Errores',
          });
        }
      } else if (data?.code === 409) {
        message.error('Valor duplicado');
      } else {
        message.error('Error en clientes');
      }
    }
  };

  const _handleDeactivate = async sId => {
    const response = await process(
      UPDATE,
      'clients',
      { status: 0 },
      { id: sId }
    );
    if (response?.ok) {
      message.success('Desactivado correctamente');
      updater();
    } else {
      message.error('Error al desactivar');
    }
  };

  return (
    <div>
      <Row justify="space-between">
        <Col className="screen-title">
          <h3>Clientes</h3>
          <h3>
            <Tag>{clients?.total ? clients.total : <LoadingOutlined />}</Tag>
          </h3>
        </Col>
        <Col>
          <Button onClick={() => setModal(true)} type="primary">
            Crear
          </Button>
        </Col>
      </Row>
      <ClientActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: handleSubmit,
          visible: modal,
        }}
      />
      <SearchBar
        elements={aSearchElements(employees.data)}
        {...{
          handleReset,
          handleSearch,
          search,
          setSearch,
        }}
      />
      <Table
        columns={aColumns}
        dataSource={clients?.data}
        loading={loading}
        pagination={{
          current: clients.skip / clients.limit + 1,
          onChange: e =>
            change(
              generateQueries(search, aSearchElements(employees.data)),
              (e - 1) * clients.limit
            ),
          pageSizeOptions: [10],
          total: clients.total,
        }}
        rowKey={row => row._id}
      />
    </div>
  );
};
