import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Checkbox,
  Form,
  Input,
  Row,
  Select,
  InputNumber,
  DatePicker,
} from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const strategyOptions = {
  'alex.glez.felix@gmail.com': [
    'FOOD',
    'STRUCTURE',
    'CASH',
    'LABOR UNION',
    'GASTRONOMIC PAMPAS GROUP',
    'GASTRONOMIC PAMPAS CASH FLOW',
    'ONSIGNA STRATEGY',
  ],
  'tarik@mail.com	': [
    'FOOD',
    'STRUCTURE',
    'CASH',
    'LABOR UNION',
    'GASTRONOMIC PAMPAS GROUP',
    'GASTRONOMIC PAMPAS CASH FLOW',
    'ONSIGNA STRATEGY',
  ],
  'opconsulting17@gmail.com': ['FOOD', 'STRUCTURE', 'CASH', 'LABOR UNION'],
  'lucero@grupopampas.com.mx': [
    'GASTRONOMIC PAMPAS GROUP',
    'GASTRONOMIC PAMPAS CASH FLOW',
  ],
};

const canSeeCheckeBoxes = [
  'alex.glez.felix@gmail.com',
  'lucero@grupopampas.com.mx',
];

export const StrategiesForm = ({ formRef, onSubmit }) => {
  const { user } = useSelector(state => ({ user: state.auth.user }));
  return (
    <Form form={formRef} layout="vertical" name="UserForm" onFinish={onSubmit}>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Estrategia"
            name="strategy"
            rules={[{ required: true }]}
          >
            <Select placeholder="Selecciona una estrategia...">
              {strategyOptions[user.email].map((strategy, index) => (
                <Select.Option key={index} value={strategy}>
                  {strategy}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Cantidad"
            name="amount"
            rules={[{ required: true }]}
            placeholder="1000"
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={value =>
                `$ ${value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item label="Fecha" name="date">
            <DatePicker placeholder="Elige una fecha" defaultValue={dayjs()} />
          </Form.Item>
        </Col>
        {canSeeCheckeBoxes.includes(user.email) && (
          <>
            <Col span={6}>
              <Form.Item
                name="substract"
                valuePropName="checked"
                style={{ paddingTop: '2em' }}
              >
                <Checkbox>Restar</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="from_office"
                valuePropName="checked"
                style={{ paddingTop: '2em' }}
              >
                <Checkbox>De oficina</Checkbox>
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Salida de dinero"
            name="exit"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input.TextArea placeholder="Pago de..." />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

StrategiesForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
