import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { process } from '../../Service/Api';

const ExcelDownload = ({ search }) => {
  console.log('search,', search);
  return (
    <Button
      type="primary"
      onClick={async () => {
        const filters = {};
        console.log(search?.date_ini?.toISOString());
        if (search?.date_ini) {
          filters.start_date = search.date_ini.toISOString();
        }
        if (search?.date_end) {
          filters.end_date = search.date_end.toISOString();
        }
        const res = await process('POST', 'strategies-excel', filters);
        const base64 = res.data.file;
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = 'Estrategias.xlsx';
        downloadLink.click();
      }}
      style={{ marginLeft: '1em' }}
    >
      Descargar Excel
    </Button>
  );
};

ExcelDownload.propTypes = {
  search: PropTypes.object.isRequired,
};

export default ExcelDownload;
