import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  message,
  Popconfirm,
  notification,
  Row,
  Table,
  Tag,
} from 'antd';
import { LoadingOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

import { useFetchAccounts, useFetchBank } from '../../Hooks';
import { AccountsActions } from './AccountActions';
import { process, SAVE, UPDATE } from '../../Service/Api';
import { SearchBar } from '../Atoms/SearchBar';
import { generateQueries } from '../../Utils/query';
import { aSearchElements, oInitState } from './AccountConstants';

export const AccountsTable = () => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [search, setSearch] = useState(oInitState);
  const [accounts, loading, change, updater] = useFetchAccounts();
  const [banks] = useFetchBank();
  const [formRef] = Form.useForm();

  const _handleDeactivate = async sId => {
    const response = await process(
      UPDATE,
      'accounts',
      { status: 0 },
      { id: sId }
    );
    if (response?.ok) {
      message.success('Desactivado correctamente');
      updater();
    } else {
      message.error('Error al desactivar');
    }
  };

  const columns = [
    {
      dataIndex: 'bank_id',
      title: 'Banco',
      render: bank_id => {
        const bank = banks?.data.find(oBank => oBank._id === bank_id);
        return bank?.name;
      },
    },
    {
      dataIndex: 'account_name',
      title: 'Nombre',
    },
    {
      dataIndex: 'account_number',
      title: 'Número de cuenta',
    },
    {
      dataIndex: 'clabe',
      title: 'Clabe',
    },
    {
      dataIndex: 'comission',
      title: 'Comisión %',
    },
    {
      dataIndex: 'office',
      title: 'Oficina',
    },
    {
      dataIndex: 'balance',
      title: 'Saldo',
      render: deposit =>
        deposit ? `$ ${deposit.toLocaleString('en-US')}` : null,
    },
    {
      dataIndex: ['created_by_user', 'full_name'],
      title: 'Creado por',
    },
    {
      dataIndex: ['updated_by_user', 'full_name'],
      title: 'Actualizado por',
    },
    {
      dataIndex: 'status',
      title: 'Estatus',
      render: status =>
        status ? (
          <Tag color="#87d068">Activo</Tag>
        ) : (
          <Tag color="#f50">Inactivo</Tag>
        ),
    },
    {
      dataIndex: '_id',
      title: 'Acciones',
      render: (_, row) => {
        return (
          <Row>
            <Col>
              <Button
                onClick={() => {
                  setSelected(row);
                  formRef.setFieldsValue(row);
                  setModal(true);
                }}
              >
                <EditTwoTone twoToneColor="#BF8A4B" />
              </Button>
              <Popconfirm
                onConfirm={() => _handleDeactivate(row._id)}
                title="Está seguro de desactivar esta cuenta?"
              >
                <Button>
                  <DeleteTwoTone twoToneColor="#BF8A4B" />
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  const _handleReset = () => {
    setSearch(oInitState);
    change();
  };

  const _handleSearch = () => change(generateQueries(search, aSearchElements));

  const _handleSubmit = async values => {
    setModalLoading(true);
    let response;
    let oSend = { ...values };
    if (selected?._id) {
      response = await process(UPDATE, 'bank-accounts', oSend, {
        id: selected._id,
      });
    } else {
      let oAccount = {
        bank_id: values.bank_id,
        account_name: values.account_name,
        account_number: values.account_number,
        clabe: values.clabe,
        balance: values.balance,
        status: values.status,
        office: values.office,
      };

      response = await process(SAVE, 'bank-accounts', oAccount);
    }
    setModalLoading(false);
    if (response?.ok) {
      message.success('Exito');
      formRef.resetFields();
      setSelected({});
      setModal(false);
      updater();
    } else {
      const { data } = response;
      if (data?.code === 400) {
        let sErrors = '';
        if (Array.isArray(data.errors)) {
          for (const oError of data.errors) {
            if (oError.type === 'unique violation') {
              sErrors += `El valor ${oError.path} ya existe en BD\n`;
            }
          }
        } else {
          for (const sKey in data?.errors) {
            sErrors += data.errors[sKey] + '\n';
          }
        }
        message.error(data?.message);
        if (sErrors !== '') {
          notification.error({
            message: sErrors,
            title: 'Errores',
          });
        }
      } else if (data?.code === 409) {
        message.error('Valor duplicado');
      } else {
        message.error('Error en cuentas');
      }
    }
    setModalLoading(false);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col className="screen-title">
          <h3>Cuentas</h3>
          <h3>
            <Tag>{accounts?.total ? accounts.total : <LoadingOutlined />}</Tag>
          </h3>
        </Col>
        <Col>
          <Button onClick={() => setModal(true)} type="primary">
            Crear
          </Button>
        </Col>
      </Row>
      <AccountsActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected: selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: _handleSubmit,
          visible: modal,
        }}
      />
      <SearchBar
        elements={aSearchElements}
        handleReset={_handleReset}
        handleSearch={_handleSearch}
        {...{ search, setSearch }}
      />
      <Table
        columns={columns}
        dataSource={accounts?.data}
        loading={loading}
        pagination={{
          current: accounts.skip / 10 + 1,
          onChange: e => change(accounts.queries, (e - 1) * 10),
          pageSizeOptions: [10],
          total: accounts.total,
        }}
        rowKey={row => row.id}
      />
    </div>
  );
};
