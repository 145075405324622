import React, { useEffect, useState } from 'react';
import { Button, Col, Checkbox, Form, Input, notification, Row } from 'antd';
import {
  UserOutlined,
  LockOutlined,
  LoginOutlined,
  SendOutlined,
} from '@ant-design/icons';
// Cimport './App.css';
import logo from './Assets/img/logo-color.svg';
import { useAuth } from './Hooks/Auth.hook';

function App() {
  const [aForget, setForget] = useState(false);
  const [newError, setNewError] = useState(0);
  const [, login, , error, loading] = useAuth();
  const [form] = Form.useForm();

  const _handleLogin = values => {
    login(values.email, values.password);
    setNewError(newError + 1);
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: error || 'Error en petición',
      });
      form.setFieldValue('password', '');
    }
  }, [error, newError]);

  return (
    <div className="login-screen">
      <Row>
        <Col span={14} className="bg-section">
          <div className="wrapper">
            <div className="bg-image" />
          </div>
        </Col>
        <Col span={10} className="login-section">
          <div className="spacer">
            {!aForget ? (
              <Form
                form={form}
                name="normal_login"
                initialValues={{
                  remember: true,
                }}
                onFinish={_handleLogin}
              >
                <div>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col span={24}>
                      <img src={logo} style={{ maxHeight: 250 }} />
                    </Col>
                  </Row>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa tu email',
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Email"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa tu Password!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                      span: 4,
                    }}
                  >
                    <Checkbox style={{ color: '#000' }}>Recuérdame</Checkbox>
                  </Form.Item>

                  <Form.Item>
                    <Row>
                      <Col span={24}>
                        <Button
                          className="login-form-button"
                          htmlType="submit"
                          icon={<LoginOutlined />}
                          loading={loading}
                          onClick={() => form.submit()}
                          type="primary"
                          size="large"
                        >
                          Acceder
                        </Button>
                      </Col>
                      {/* <Col span={12}>
                  <Button
                    className="login-form-button"
                    type="dashed"
                    icon={<QuestionCircleOutlined />}
                    onClick={() => {
                      setForget(!aForget);
                    }}
                  >
                    Olvidé mi contraseña
                  </Button>
                </Col> */}
                    </Row>
                  </Form.Item>
                </div>
              </Form>
            ) : (
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
              >
                <div className="login-form-container">
                  <h2>Olvidaste tu contraseña?</h2>
                  <h4>
                    ¿No recuerdas tu contraseña? No te preocupes te lo enviamos
                    por correo.
                  </h4>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa tu correo',
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Row>
                      <Col span={12}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                          icon={<SendOutlined />}
                        >
                          Recuperar Contraseña
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button
                          className="login-form-button"
                          type="dashed"
                          icon={<LoginOutlined />}
                          onClick={() => {
                            setForget(!aForget);
                          }}
                        >
                          Iniciar Sesion
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </div>
              </Form>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default App;
