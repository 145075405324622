import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Select } from 'antd';

const { Option } = Select;

export const BankForm = ({ formRef, isNew, selectLoading, onSubmit }) => {
  return (
    <Form form={formRef} layout="vertical" name="BankForm" onFinish={onSubmit}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item
            label="Nombre"
            name="name"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Nombre Comercial"
            name="trade_name"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item
            label="Clave"
            name="code"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Estatus" name="status" initialValue={1}>
            <Select disabled={isNew} loading={selectLoading}>
              <Option value={1}>Activo</Option>
              <Option value={0}>Inactivo</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

BankForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
  isNew: PropTypes.bool,
  selectLoading: PropTypes.bool,
};
