import { useCallback, useEffect, useState } from 'react';
import { FIND, process } from '../Service/Api';
import { message } from 'antd';

export const useFetchMovements = (queries = '', skip = 0, limit = 10) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ queries, limit, skip });

  const getMovements = useCallback(async () => {
    setLoading(true);
    //Descending order
    const updatedQueries = params.queries
      ? `${params.queries}&$sort[updated_at]=-1&is_submovement=false`
      : '$sort[updated_at]=-1&is_submovement=false';

    const response = await process(
      FIND,
      'movements',
      {},
      { ...params, queries: updatedQueries }
    );
    if (response?.ok) {
      setData(response.data);
    } else {
      message.error('Error al consultar los movimientos');
    }
    setLoading(false);
  }, [process, params]);

  useEffect(() => {
    getMovements();
  }, [getMovements]);

  const onChangeParams = (q = '', s = 0, l = 10) =>
    setParams({ queries: q, limit: l, skip: s });

  const update = () => getMovements();

  return [data, loading, onChangeParams, update];
};
