import React from 'react';
import { Select, Tag } from 'antd';

export const aSearchElements = [
  {
    label: 'Nombre',
    name: 'name',
    or: 'true',
    type: 'input',
  },
  {
    label: 'Apellido Paterno',
    name: 'last_name',
    or: 'true',
    type: 'input',
  },
  {
    label: 'Apellido Materno',
    name: 'mothers_name',
    or: 'true',
    type: 'input',
  },
  {
    label: 'Tipo de empleado',
    name: 'type',
    placeholder: 'Todos',
    type: 'select',
    values: () => (
      <>
        <Select.Option value={2}>
          <Tag color="purple"> Ejecutivo A</Tag>
        </Select.Option>
        <Select.Option value={0}>
          <Tag color="blue"> Ejecutivo B</Tag>
        </Select.Option>
        <Select.Option value={1}>
          <Tag color="green"> Intermediario A</Tag>
        </Select.Option>
        <Select.Option value={3}>
          <Tag color="orange"> Intermediario B</Tag>
        </Select.Option>
      </>
    ),
  },
  {
    label: 'Oficina',
    name: 'office',
    placeholder: 'Todas',
    type: 'select',
    values: () => (
      <>
        <Select.Option value={'ALIQUANTUM'}>ALIQUANTUM</Select.Option>
        <Select.Option value={'CUMBRES'}>CUMBRES</Select.Option>
      </>
    ),
  },
  {
    label: 'Estatus',
    name: 'status',
    placeholder: 'Todos',
    type: 'select',
    values: () => (
      <>
        <Select.Option value={1}>Activo</Select.Option>
        <Select.Option value={0}>Inactivo</Select.Option>
      </>
    ),
  },
];

export const oInitState = {
  name: '',
  last_name: '',
  mothers_name: '',
};
