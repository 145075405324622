import React from 'react';
import { Select } from 'antd';

export const aSearchElements = () => [
  {
    label: 'Fecha',
    name: 'date',
    type: 'date',
  },
  {
    label: 'Descripcion',
    name: 'description',
    type: 'input',
    whitelist: true,
  },
  {
    label: 'Cliente',
    name: 'client_id',
    placeholder: 'Todos',
    type: 'client',
  },
  {
    label: 'Cuenta',
    name: 'account_id',
    placeholder: 'Todos',
    type: 'cuenta',
  },
  {
    label: 'Estatus',
    name: 'status',
    placeholder: 'Todos',
    type: 'select',
    values: () => (
      <>
        <Select.Option value={2}>Conciliado</Select.Option>
        <Select.Option value={1}>Pagado</Select.Option>
        <Select.Option value={0}>Pendiente</Select.Option>
      </>
    ),
  },
  {
    label: 'Oficina',
    name: 'office',
    placeholder: 'Todas',
    type: 'select',
    values: () => (
      <>
        <Select.Option value={'ALIQUANTUM'}>ALIQUANTUM</Select.Option>
        <Select.Option value={'CUMBRES'}>CUMBRES</Select.Option>
      </>
    ),
  },
];

export const oInitState = {
  date: '',
  description: '',
  client_id: undefined,
  bank_id: undefined,
  status: undefined,
};
