import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Checkbox, Form, Input, Row, Select } from 'antd';
import { SearchSelector } from '../Atoms/SearchSelector';
import { useFetchAccountingAccounts } from '../../Hooks';
import { generateQueries } from '../../Utils/query';
import debounce from 'lodash/debounce';

const { Option } = Select;

const AccountSearch = [
  {
    name: 'status',
    type: 'select',
  },
];

export const AccountingAccountForm = ({ formRef, isUpdating, onSubmit }) => {
  const [showParenAccount, setShowParenAccount] = useState(false);
  const [parenAccount, setParentAccount] = useState(null);
  const [accounts, loading, query] = useFetchAccountingAccounts('status=1');

  useEffect(() => {
    const values = formRef.getFieldsValue(true);

    if (values.upper_account) {
      setShowParenAccount(true);
      setParentAccount(values.upper_account);
    }
  }, [formRef]);

  const handleSearchAccount = s => {
    if (s.length >= 3) {
      const sQueries = generateQueries({ name: s, status: 1 }, AccountSearch);
      query(sQueries, 0, 50);
    } else {
      query();
    }
  };

  return (
    <Form form={formRef} layout="vertical" name="BankForm" onFinish={onSubmit}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Nombre" name="name">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Cuenta Contable" name="account">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <Form.Item label="Parámetro 1" name="param1">
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Parámetro 2" name="param2">
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Parámetro 2" name="param3">
            <Input type="number" />
          </Form.Item>
        </Col>
        {!isUpdating || !parenAccount ? (
          <Col
            span={showParenAccount ? 12 : 24}
            style={{ textAlign: 'center' }}
          >
            <Form.Item label=" ">
              <Checkbox
                onChange={({ target: { checked } }) => {
                  setShowParenAccount(checked);
                }}
              >
                ¿Esta cuenta esta relacionada con alguna otra cuenta?
              </Checkbox>
            </Form.Item>
          </Col>
        ) : null}
        {showParenAccount ? (
          <Col span={isUpdating && parenAccount ? 24 : 12}>
            <Form.Item label="Cuenta Superior" name="upper_account">
              <SearchSelector
                handleSearch={debounce(handleSearchAccount, 300)}
                loading={loading}
                placeholder="Buscar Cuenta"
              >
                {accounts?.data.map(oAccount => (
                  <Select.Option key={oAccount._id} value={oAccount._id}>
                    {oAccount.name} - {oAccount.account}
                  </Select.Option>
                ))}
              </SearchSelector>
            </Form.Item>
          </Col>
        ) : null}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Naturaleza Contable" name="nature">
            <Select>
              <Option value="C">Cargo</Option>
              <Option value="A">Abono</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Estatus" name="status" initialValue={1}>
            <Select>
              <Option value={1}>Activo</Option>
              <Option value={0}>Inactivo</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

AccountingAccountForm.propTypes = {
  formRef: PropTypes.object,
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
