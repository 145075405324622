import React from 'react';
import EmployeesTable from '../../Components/Employees/EmployeesTable';

export const EmployeeScreen = () => {
  return (
    <div className="client-screen">
      <EmployeesTable />
    </div>
  );
};
