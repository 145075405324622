import React from 'react';
import { BankTable } from '../../Components/Bank/BankTable';

export const BanksScreen = () => {
  return (
    <div className="bank-scren">
      <BankTable />
    </div>
  );
};
