import React from 'react';
import { Select } from 'antd';

export const aSearchElements = (aAccounts = []) => [
  {
    label: 'Cuenta Contable',
    name: 'accounting_account_id',
    placeholder: 'Todos',
    type: 'select-multiple',
    mode: 'multiple',
    values: () => (
      <>
        {aAccounts.map(oAccount => (
          <Select.Option key={oAccount._id} value={oAccount._id}>
            {oAccount.name} - {oAccount.account}
          </Select.Option>
        ))}
      </>
    ),
  },
  {
    label: 'Fecha',
    name: 'date',
    type: 'date',
  },
  {
    label: 'Concepto',
    name: 'concept',
    or: true,
    placeholder: 'Concepto',
    type: 'input',
  },
];

export const oInitState = {
  account_id: undefined,
  concept: '',
};
