/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Card, Col, Row, Table, Tag, Timeline } from 'antd';
import { LineChart } from '../../Components/Atoms/LineChart';
import { RingChart } from '../../Components/Atoms/RingChart';
import { useFetchClients } from '../../Hooks';
import { useNavigation } from '../../Hooks';

// TODO: Remove these two later
const randomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

// ? https://stackoverflow.com/questions/45302779/javascript-random-generate-time-in-12-hour-format#:~:text=var%20myDate%20%3D%20new%20Date()%3B,randomTime(hour%2C%20minute)%3B
const randomTime = () => {
  let hrs = Math.round(Math.random() * 24),
    mins = Math.round(Math.random() * 60);
  var hFormat = hrs < 10 ? '0' : '';
  var mFormat = mins < 10 ? '0' : '';
  var amPm = hrs < 12 ? 'AM' : 'PM';
  var is12 = hrs % 12 === 0;

  return amPm === 'AM' && !is12
    ? String(hFormat + hrs + ':' + mFormat + mins + ' ' + amPm)
    : 'AM' && is12
    ? String(12 + ':' + mFormat + mins + ' ' + amPm)
    : is12
    ? String(hFormat + hrs + ':' + mFormat + mins + ' ' + amPm)
    : String(hFormat + (hrs - 12) + ':' + mFormat + mins + ' ' + amPm);
};

const TEST_DATA = [
  {
    date: '07/01',
    value: 26,
  },
  {
    date: '07/02',
    value: 0,
  },
  {
    date: '07/03',
    value: 35,
  },
  {
    date: '07/04',
    value: 14,
  },
  {
    date: '07/05',
    value: 28,
  },
  {
    date: '07/06',
    value: 4,
  },
  {
    date: '07/07',
    value: 6,
  },
  {
    date: '07/08',
    value: 15,
  },
];

const aInitialCards = [
  {
    title: 'Ventas',
    quantity: randomInt(100000, 500000),
    prefix: '$',
    suffix: '',
    bgc: '#BAEE55',
    color: '#001529',
  },
  {
    title: 'Promedio Altas',
    quantity: randomInt(50, 60),
    prefix: '',
    suffix: '%',
    bgc: '#EFEFEF',
    color: '#001529',
  },
  {
    title: 'Promedio Ganancia',
    quantity: randomInt(60, 70),
    prefix: '',
    suffix: '%',
    bgc: '#001529',
    color: '#FFF',
  },
  {
    title: 'Facturas pendientes',
    quantity: randomInt(40, 50),
    prefix: '',
    suffix: '',
    bgc: '#336BF6',
    color: '#FFF',
  },
  {
    title: 'Facturas Pagadas',
    quantity: randomInt(90, 120),
    prefix: '',
    suffix: '',
    bgc: '#BAEE55',
    color: '#001529',
  },
];

export const InitialScreen = () => {
  // eslint-disable-next-line no-unused-vars
  const [cards, setCards] = useState(aInitialCards);
  const [line, setLine] = useState(TEST_DATA);
  const [activities, setActivities] = useState(
    [...Array(5).keys()].map(i => ({
      title: `Notification ${i + 1}`,
      time: randomTime(),
    }))
  );
  const [clients, clientsLoading] = useFetchClients('status=1', 0, 5);
  const [, nav] = useNavigation();

  return (
    <div className="initial-screen">
      <h1>Bienvenidos</h1>
    </div>
  );
};
