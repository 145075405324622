import React from 'react';
import { MovementTable } from '../../Components/Movements/MovementTable';

export const MovementScreen = () => {
  return (
    <div className="movement-screen">
      <MovementTable />
    </div>
  );
};
