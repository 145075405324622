import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  notification,
  Button,
  Typography,
  Tabs,
} from 'antd';

import { DeleteTwoTone } from '@ant-design/icons';

import { SearchSelector } from '../Atoms/SearchSelector';
import { useFetchAccounts, useFetchClients } from '../../Hooks';
import { generateQueries } from '../../Utils/query';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

const clientSearch = [
  {
    name: 'name,trade_name',
    or: true,
    type: 'input',
  },
  {
    name: 'status',
    type: 'select',
  },
];

export const MovementForm = ({ formRef, onSubmit }) => {
  const [accounts] = useFetchAccounts('', 0, 100);
  const [clients, loading, query] = useFetchClients('', 0, 100);
  const [returnAmount, setReturnAmount] = useState(
    formRef.getFieldValue('return_amount') ? 2 : false
  );
  const [mixed, setMixed] = useState(
    formRef.getFieldValue('movement_type') === 3 ? 3 : false
  );
  const [quantityMovement, setQuantityMovement] = useState([]);
  const [counter, setCounter] = useState(0);
  const { user } = useSelector(state => ({ user: state.auth.user }));
  const [withdrawalValue, setWithdrawalValue] = useState(
    formRef.getFieldValue('withdrawal') || 0
  );
  const [depositValue, setDepositValue] = useState(
    formRef.getFieldValue('deposit') || 0
  );
  const [disableSubSalaries, setDisableSubSalaries] = useState(
    !formRef.getFieldValue('balances')?.length > 0
  );
  const [balances, setBalances] = useState(
    formRef.getFieldValue('balances') || []
  );
  const [associated_companies, setAssociatedCompanies] = useState([]);
  const handleSearchClients = s => {
    if (s.length >= 3) {
      let sQueries = generateQueries(
        { 'name,trade_name': s, status: 1 },
        clientSearch
      );
      query(sQueries, 0, 100);
    } else {
      query('', 0, 100);
    }
  };

  const handlerClient = e => {
    const client = clients.data.find(fClient => fClient._id === e);
    if (client?.associated_companies) {
      setReturnAmount(client?.commission_type);
      setDisableSubSalaries(false);
      setAssociatedCompanies(client?.associated_companies);
    } else {
      setReturnAmount(false);
      setDisableSubSalaries(true);
      setAssociatedCompanies([]);
    }
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    Object.entries(quantityMovement).forEach(([index]) => {
      const amount =
        parseFloat(formRef.getFieldValue(`mov_amount_${index}`)) || 0;
      totalAmount += amount;
    });
    return totalAmount;
  };

  const handleFinish = values => {
    //Validation of the total amount for mixed movements
    if (mixed === 3) {
      const totalAmount = calculateTotalAmount();
      const total = withdrawalValue || depositValue;
      if (totalAmount > total) {
        notification.error({
          message: 'Error',
          description:
            'La suma de los montos no puede exceder el valor de Retiro o Depósito',
        });
        return;
      }
    }

    if (
      (values.deposit && values.withdrawal) ||
      (!values.deposit && !values.withdrawal)
    ) {
      notification.error({
        message: 'Error',
        description:
          'Debes completar solo uno de los campos: deposito o retiro',
      });
    } else {
      if (balances.length > 0) {
        values.balances = balances;
      }
      onSubmit(values);
    }
  };

  const handleMixedMovement = e => {
    setMixed(e);
    if (e === 3) {
      setQuantityMovement(prevQuantity => [
        ...prevQuantity,
        prevQuantity.length + 1,
      ]);
    } else {
      setQuantityMovement([]);
    }
  };

  const handleAddMovement = () => {
    const newIndex = counter + 1;
    setQuantityMovement(prevQuantity => ({
      ...prevQuantity,
      [newIndex]: { amount: null, method: null },
    }));
    setCounter(newIndex);
  };

  const handleRemoveMovement = index => {
    setQuantityMovement(prevQuantity => {
      const updatedQuantity = { ...prevQuantity };
      delete updatedQuantity[index];
      return updatedQuantity;
    });
  };

  const validateAmount = (rule, value) => {
    if (value && mixed === 3) {
      if (withdrawalValue && parseFloat(value) > parseFloat(withdrawalValue)) {
        return Promise.reject('El monto no puede ser mayor al Retiro');
      }
      if (depositValue && parseFloat(value) > parseFloat(depositValue)) {
        return Promise.reject('El monto no puede ser mayor al Depósito');
      }
    }
    return Promise.resolve();
  };

  useEffect(() => {
    const movements_mixed = formRef.getFieldValue('movements_mixed');
    if (movements_mixed) {
      //Updates the status of movementsMixed with the values movements_mixed
      setQuantityMovement(movements_mixed);
      setCounter(movements_mixed.length);
      movements_mixed.forEach((movement, index) => {
        formRef.setFieldsValue({
          [`mov_amount_${index}`]: movement.amount,
          [`mov_method_${index}`]: movement.type,
          [`mov_accountId_${index}`]: movement.account_id,
        });
      });
    }
  }, []);

  const form = () => (
    <Form
      form={formRef}
      layout="vertical"
      name="MovementForm"
      onFinish={handleFinish}
      disabled={user.rol === 'oficina'}
    >
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Cliente"
            name="client_id"
            rules={[{ required: false, message: 'Campo requerido' }]}
          >
            <SearchSelector
              allowClear
              handleSearch={debounce(handleSearchClients, 500)}
              loading={loading}
              onChange={e => {
                query();
                handlerClient(e);
              }}
              placeholder="Seleccionar cliente"
            >
              {clients?.data.map(oClient => {
                console.log('oClient', oClient);
                if (oClient.name) {
                  return (
                    <Select.Option key={oClient._id} value={oClient._id}>
                      {`${oClient.name} ${oClient.last_name || ''} ${
                        oClient.mother_name || ''
                      }`}
                    </Select.Option>
                  );
                } else if (oClient.trade_name) {
                  return (
                    <Select.Option key={oClient._id} value={oClient._id}>
                      {oClient.trade_name}
                    </Select.Option>
                  );
                }
              })}
            </SearchSelector>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Fecha"
            name="date"
            initialValue={new Date().toISOString().slice(0, 10)}
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="date" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Retiro" name="withdrawal">
            <Input
              type="number"
              onChange={e => setWithdrawalValue(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Depósito" name="deposit">
            <Input
              type="number"
              onChange={e => setDepositValue(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Saldo"
            name="balance"
            rules={[{ required: false, message: 'Campo requerido' }]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Cuenta"
            name="account_id"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Select>
              <Select.Option value={0}>Inactivo</Select.Option>
              {accounts?.data.map(oAccount => (
                <Select.Option key={oAccount._id} value={oAccount._id}>
                  {oAccount.office
                    ? `${oAccount.account_name} - ${oAccount.office.substring(
                        0, // eslint-disable-line
                        5 // eslint-disable-line
                      )}` // eslint-disable-line
                    : `${oAccount.account_name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Tipo de movimiento"
            name="movement_type"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Select onChange={e => handleMixedMovement(e)}>
              <Select.Option value={0}>Tranferencia</Select.Option>
              <Select.Option value={1}>Efectivo</Select.Option>
              <Select.Option value={2}>Asimilado</Select.Option>
              <Select.Option value={3}>Mixto</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {returnAmount === 2 && (
          <Col span={12}>
            <Form.Item
              label="Retorno cliente"
              name="return_amount"
              rules={[{ required: true, message: 'Campo requerido' }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        )}
        {mixed === 3 && (
          <Col span={24}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Button
                onClick={handleAddMovement}
                style={{ lineHeight: 'unset' }}
              >
                +
              </Button>
            </div>
          </Col>
        )}
        {mixed === 3 &&
          Object.entries(quantityMovement).map(([index]) => (
            <React.Fragment key={index}>
              <Col span={7}>
                <Form.Item
                  label={
                    <Typography.Text ellipsis className="long-name">
                      Monto
                    </Typography.Text>
                  }
                  name={`mov_amount_${index}`}
                  rules={[
                    { required: true, message: 'Campo requerido' },
                    { validator: validateAmount },
                  ]}
                >
                  <Input type="number" suffix="$" />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label="Tipo de movimiento"
                  name={`mov_method_${index}`}
                  rules={[{ required: true, message: 'Campo requerido' }]}
                >
                  <Select placeholder={`Tipo de Movimiento`}>
                    <Select.Option value={0}>Tranferencia</Select.Option>
                    <Select.Option value={1}>Efectivo</Select.Option>
                    <Select.Option value={2}>Asimilado</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Cuenta"
                  name={`mov_accountId_${index}`}
                  rules={[{ required: true, message: 'Campo requerido' }]}
                >
                  <Select>
                    {accounts?.data.map(oAccount => (
                      <Select.Option key={oAccount._id} value={oAccount._id}>
                        {oAccount.account_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Button
                    onClick={() => handleRemoveMovement(index)}
                    style={{ lineHeight: 'unset' }}
                    danger
                  >
                    -
                  </Button>
                </div>
              </Col>
            </React.Fragment>
          ))}
        <Col span={6}>
          <Form.Item name="spei" label="Numero de SPEI">
            <Input type="number" placeholder="0" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="spei_amount" label="Monto por SPEI">
            <Input type="number" placeholder="0" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="spei_charge" label="Cobro SPEI a">
            <Select defaultValue={0}>
              <Select.Option value={0}>Ambos</Select.Option>
              <Select.Option value={1}>Cliente</Select.Option>
              <Select.Option value={2}>Oficina</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Descripción"
            name="description"
            rules={[{ required: true, message: 'Campo requerido' }]}
            initialValue={
              ('' + new Date().getFullYear()).substring(2, 4) +
              ('0' + (new Date().getMonth() + 1)).slice(-2) +
              '-'
            }
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const balance = () => {
    return (
      <Form>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <h2>Saldos</h2>
          <Button onClick={() => setBalances([...balances, {}])}>
            Agregar Saldo
          </Button>
        </div>
        {balances.map((bal, index) => (
          <Row gutter={[11, 11, 2]} key={`row-${index}`}>
            <Col span={11} key={index}>
              <Form.Item label="Razon Social" name={`razon-${index}`}>
                <Select
                  type="text"
                  onChange={e => {
                    setBalances(prevBalances => {
                      const updatedBalances = [...prevBalances];
                      updatedBalances[index].razon = e;
                      return updatedBalances;
                    });
                  }}
                  defaultValue={bal.razon}
                >
                  {associated_companies?.map(company => (
                    <Select.Option key={company._id} value={company.trade_name}>
                      {company.trade_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Saldo" name={`balnce-${index}`}>
                <Input
                  type="number"
                  onChange={e => {
                    setBalances(prevBalances => {
                      const updatedBalances = [...prevBalances];
                      updatedBalances[index].saldo = e.target.value;
                      return updatedBalances;
                    });
                  }}
                  defaultValue={bal.saldo}
                />
              </Form.Item>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  setBalances(prevBalances => {
                    const updatedBalances = [...prevBalances];
                    updatedBalances.splice(index, 1);
                    return updatedBalances;
                  });
                }}
              >
                <DeleteTwoTone twoToneColor="#BF8A4B" />
              </Button>
            </Col>
          </Row>
        ))}
      </Form>
    );
  };

  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        { key: 1, label: 'Datos movimientos', children: form() },
        {
          key: 2,
          label: 'Saldos',
          children: balance(),
          disabled: disableSubSalaries,
        },
      ]}
    />
  );
};

MovementForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
