import React from 'react';
import { StrategiesTable } from '../../Components/Strategies/StrategiesTable';

export const StrategiesScreen = () => {
  return (
    <div className="role-screen">
      <StrategiesTable />
    </div>
  );
};
