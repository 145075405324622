export const solicitudesColumns = [
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Monto',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Estatus',
    dataIndex: 'status',
    key: 'status',
  },
];

export const solicitudesData = [
  {
    key: '1',
    date: '2020-03-25',
    amount: 1000,
    status: 'Autorizada',
  },
  {
    key: '2',
    date: '2020-03-26',
    amount: 500,
    status: 'Autorizada',
  },
  {
    key: '3',
    date: '2020-03-27',
    amount: 700,
    status: 'Rechazada',
  },
];

export const MovimientosColumns = [
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Referencia',
    dataIndex: 'reference',
    key: 'reference',
  },
  {
    title: 'Monto',
    dataIndex: 'amount',
    key: 'amount',
  },
];

export const MovimientosData = [
  {
    key: '1',
    date: '2020-03-25',
    reference: 'Pago de nómina',
    amount: 1000,
  },
  {
    key: '2',
    date: '2020-03-26',
    reference: 'Pago de nómina',
    amount: 500,
  },
  {
    key: '3',
    date: '2020-03-27',
    reference: 'Pago por solicitud de adelanto',
    amount: 700,
  },
];

export const ContratosData = [
  {
    title: '📁25 Marzo 2020 por $1000',
    key: '1',
    children: [
      {
        title: '📄Aviso de privacidad',
        key: '1-1',
      },
      {
        title: '📄Contrato de privacidad patron',
        key: '1-2',
      },
      {
        title: '📄Intruccion irrevocable',
        key: '1-3',
      },
      {
        title: '📄Caratula de contrato',
        key: '1-4',
      },
      {
        title: '📄Clausulado de adelantos',
        key: '1-5',
      },
    ],
  },
  {
    title: '📁26 Marzo 2020 por $500',
    key: '2',
    children: [
      {
        title: '📄Aviso de privacidad',
        key: '2-1',
      },
      {
        title: '📄Contrato de privacidad patron',
        key: '2-2',
      },
      {
        title: '📄Intruccion irrevocable',
        key: '2-3',
      },
      {
        title: '📄Caratula de contrato',
        key: '2-4',
      },
      {
        title: '📄Clausulado de adelantos',
        key: '2-5',
      },
    ],
  },
];
