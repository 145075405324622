import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Select } from 'antd';
import { useFetchBank } from '../../Hooks/Bank.hook';

const { Option } = Select;

export const AccountForm = ({ formRef, isNew, selectLoading, onSubmit }) => {
  const [bank, cLoading] = useFetchBank();

  return (
    <Form
      form={formRef}
      layout="vertical"
      name="AccountForm"
      onFinish={onSubmit}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Banco" name="bank_id">
            <Select loading={cLoading}>
              {bank?.data.map(oBank => (
                <Select.Option key={oBank._id} value={oBank._id}>
                  {oBank.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Nombre cuenta" name="account_name">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Numero cuenta" name="account_number">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Clabe" name="clabe">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Saldo" name="balance">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Estatus" name="status" initialValue={1}>
            <Select disabled={isNew} loading={selectLoading}>
              <Option value={1}>Activo</Option>
              <Option value={0}>Inactivo</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Comisión" name="comission">
            <Input suffix="%" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Oficina" name="office">
            <Select>
              <Option value="CUMBRES">Cumbres</Option>
              <Option value="ALIQUANTUM">Aliquantum</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

AccountForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
  isNew: PropTypes.bool,
  selectLoading: PropTypes.bool,
};
