import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { DeleteTwoTone, SearchOutlined } from '@ant-design/icons';
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';
import { useFetchClients, useFetchAccounts, useFetchBank } from '../../Hooks';
import { generateQueries } from '../../Utils/query';
import debounce from 'lodash/debounce';
import { SearchSelector } from '../Atoms/SearchSelector';

const { RangePicker } = DatePicker;

export const SearchBar = ({
  elements,
  handleReset,
  handleSearch,
  search,
  setSearch,
}) => {
  const aVisibles = elements.filter(({ visible = true }) => visible);
  const nFieldsSize = Math.round(16 / aVisibles.length);
  const [bank, loading, query] = useFetchBank();
  const [clients, cLoading, cQuery] = useFetchClients();
  const [accounts, aLoading, aQuery] = useFetchAccounts();
  const companySearch = [
    {
      name: 'name',
      type: 'input',
    },
    {
      name: 'status',
      type: 'select',
    },
  ];
  const handleSearchCompanies = s => {
    if (s.length > 3) {
      let sQueries = generateQueries({ name: s, status: 1 }, companySearch);
      query(sQueries, 0, 50);
    } else {
      query();
    }
  };

  const handleSearchClients = s => {
    if (s.length > 3) {
      let sQueries = generateQueries({ name: s, status: 1 }, companySearch);
      cQuery(sQueries, 0, 50);
    } else {
      cQuery();
    }
  };

  const handleSearchAccounts = s => {
    if (s.length > 3) {
      let sQueries = generateQueries({ name: s, status: 1 }, companySearch);
      aQuery(sQueries, 0, 50);
    } else {
      aQuery();
    }
  };

  const getDateForm = ({ name, value }) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  function removeTime(moment = new Date(), isEnd = false) {
    moment.set({
      hour: isEnd ? 23 : 0,
      minute: isEnd ? 59 : 0,
      second: isEnd ? 59 : 0,
      millisecond: isEnd ? 999 : 0,
    });

    return moment;
  }

  const handleDates = aDates => {
    if (aDates) {
      setSearch({
        ...search,
        date_ini: removeTime(aDates[0]),
        date_end: removeTime(aDates[1], true),
      });
    }
  };

  return (
    <Form layout="vertical">
      <Row
        gutter={[20, 20]}
        style={{ flexDirection: 'row-reverse', justifyContent: 'start' }}
      >
        <Col>
          <Form.Item label="&nbsp;">
            <Button
              onClick={handleSearch}
              style={{ borderStyle: 'none', boxShadow: 'none' }}
            >
              <SearchOutlined />
            </Button>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="&nbsp;">
            {handleReset && (
              <Button
                onClick={handleReset}
                style={{
                  borderStyle: 'none',
                  boxShadow: 'none',
                  color: '#F05249',
                  marginLeft: 10,
                }}
              >
                <DeleteTwoTone twoToneColor="#F55" />
              </Button>
            )}
          </Form.Item>
        </Col>
        {elements
          .filter(({ visible = true }) => visible)
          .reverse()
          .map(
            (
              {
                label,
                name,
                placeholder = '',
                sizes = { small: 12, normal: 0 },
                type,
                values,
                mode = '',
              },
              nIndex
            ) => (
              <Col
                key={nIndex}
                md={sizes.normal || nFieldsSize}
                sm={sizes.small || 12}
                xs={24}
              >
                <Form.Item label={label}>
                  {type === 'input' || type === 'input-fixed' ? (
                    <Input
                      name={name}
                      onChange={({ target }) => getDateForm(target)}
                      placeholder={placeholder}
                      type="text"
                      value={search[name]}
                    />
                  ) : type === 'select' || type === 'select-multiple' ? (
                    <Select
                      name={name}
                      mode={mode}
                      onChange={value => getDateForm({ name, value })}
                      placeholder={placeholder}
                      style={{ width: '100%' }}
                      value={search[name]}
                    >
                      {typeof values === 'function' && values()}
                    </Select>
                  ) : type === 'company' ? (
                    <SearchSelector
                      name={name}
                      allowClear
                      handleSearch={debounce(handleSearchCompanies, 300)}
                      loading={loading}
                      onChange={value => {
                        query();
                        getDateForm({ name, value });
                      }}
                      placeholder="Seleccionar Compañia"
                      value={search[name]}
                    >
                      {bank?.data.map(oClient => (
                        <Select.Option key={oClient._id} value={oClient._id}>
                          {oClient.name}
                        </Select.Option>
                      ))}
                    </SearchSelector>
                  ) : type === 'client' ? (
                    <SearchSelector
                      name={name}
                      allowClear
                      handleSearch={debounce(handleSearchClients, 300)}
                      loading={cLoading}
                      onChange={value => {
                        cQuery();
                        getDateForm({ name, value });
                      }}
                      placeholder="Seleccionar Cliente"
                      value={search[name]}
                    >
                      {clients?.data.map(oClient => (
                        <Select.Option key={oClient._id} value={oClient._id}>
                          {oClient.name
                            ? `${oClient?.name} ${oClient?.last_name}`
                            : oClient?.trade_name}
                        </Select.Option>
                      ))}
                    </SearchSelector>
                  ) : type === 'date' ? (
                    <RangePicker
                      locale={locale}
                      onChange={handleDates}
                      style={{ width: '100%' }}
                      value={[search?.date_ini, search?.date_end]}
                    />
                  ) : type === 'cuenta' ? (
                    <SearchSelector
                      name={name}
                      allowClear
                      handleSearch={debounce(handleSearchAccounts, 300)}
                      loading={aLoading}
                      onChange={value => {
                        cQuery();
                        getDateForm({ name, value });
                      }}
                      placeholder="Seleccionar Cuenta"
                      value={search[name]}
                    >
                      {accounts?.data.map(account => (
                        <Select.Option key={account._id} value={account._id}>
                          {account.account_name}
                        </Select.Option>
                      ))}
                    </SearchSelector>
                  ) : null}
                </Form.Item>
              </Col>
            )
          )}
      </Row>
    </Form>
  );
};

SearchBar.propTypes = {
  elements: PropTypes.array.isRequired,
  handleReset: PropTypes.func,
  handleSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  setSearch: PropTypes.func.isRequired,
};
