import React from 'react';
import { AccountsTable } from '../../Components/Accounts/AccountsTable';

export const AccountsScreen = () => {
  return (
    <div className="bank-screen">
      <AccountsTable />
    </div>
  );
};
